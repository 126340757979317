/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Box,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Formik, Form,
} from 'formik';
import * as Yup from 'yup';
import {
  BelowForgotText,
  ButtonForgotText,
  LoginFormBox,
  WelcomeTextStyling,
  CustomButton,
  BelowForgotBox,
} from './style';
import route from '../../utils/route';
import userService from '../../services/user.service';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import commonUtils from '../../utils/common/common';

function Forgot() {
  const [loading, setLoading] = useState(false);
  const [navigateSnackbarState, setNavigateSnackbarState] = useState({
    navigateText: '',
    severity: '',
    state: false,
    message: '',
  });
  const navigate = useNavigate();

  const initialValues = { email: '' };

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().matches(commonUtils.validateEmail, 'Email is invalid').required('Please enter your email'),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const res = await userService.forgotPassword({ email: values.email });
      setNavigateSnackbarState({
        navigateText: '',
        severity: 'success',
        state: true,
        message: res || 'Check Your Email.!',
      });
      resetForm();
    } catch (err) {
      setNavigateSnackbarState({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.data.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  document.body.classList.add('loginBody');

  return (
    <Box>
      <Grid
        container
        display="flex"
        data-testid="login-box"
        justifyContent="center"
        height="100vh"
        alignItems="center"
      >
        <Grid sm={4} xs={12}>
          <LoginFormBox item>
            <Paper elevation={3} sx={{ py: 4, boxShadow: '-4px 4px 0px #FFC72B,1px 1px 3px rgba(0, 0, 0, 0.2)' }}>
              <WelcomeTextStyling>Forgot your password?</WelcomeTextStyling>
              <BelowForgotBox>
                <BelowForgotText>
                  Please enter the email address associated with your account and we
                  will email you a link to reset your password.
                </BelowForgotText>
              </BelowForgotBox>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  isSubmitting, handleChange, handleBlur, values, errors, touched,
                }) => (
                  <Form>
                    <div className="input-wrap" style={{ padding: '12px 15px', textAlign: 'start' }}>
                      <label className="label" htmlFor="email">Email Address</label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                        id="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {touched.email && errors.email ? (
                        <span style={{ fontSize: '14px', color: 'red', padding: 2 }}>{errors.email}</span>
                      ) : null}
                    </div>

                    <Grid display="flex" justifyContent="center">
                      {loading && <CircularProgress />}
                    </Grid>

                    {!loading && (
                      <Grid display="flex">
                        <CustomButton
                          type="submit"
                          data-testid="reset-box"
                          disabled={isSubmitting || !values.email || !!errors.email}
                        >
                          <ButtonForgotText>SUBMIT</ButtonForgotText>
                        </CustomButton>
                        <CustomButton onClick={() => navigate(`/${route.ROUTE_LOGIN}`)}>
                          <span className="material-icons">arrow_back</span>
                          <ButtonForgotText>Back to login</ButtonForgotText>
                        </CustomButton>
                      </Grid>
                    )}
                  </Form>
                )}
              </Formik>
            </Paper>
          </LoginFormBox>
        </Grid>
      </Grid>

      {navigateSnackbarState.state && (
        <NavigateSnackbar
          snackbarObj={navigateSnackbarState}
          setSnackbar={setNavigateSnackbarState}
        />
      )}
    </Box>
  );
}

export default Forgot;
