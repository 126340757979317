import axiosInstance from './axiosInstance';

class ReportService {
  async addProject(projectDetail) {
    try {
      const response = await axiosInstance.post('/project/add', projectDetail);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getAllProjects(page, size) {
    try {
      const response = await axiosInstance.get(`/project/getAll?pageNo=${page}&pageSize=${size}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async projectsInviteByCurrentUser(page, size) {
    try {
      const response = await axiosInstance.get(`/projectInvite/getProjectsByCurrentUser?pageNumber=${page}&pageSize=${size}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getProjectById(id) {
    try {
      const response = await axiosInstance.get(`/project/getAll/${id}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getSharedReport(id) {
    try {
      const response = await axiosInstance.get(`/public/getSharedReport/${id}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async deleteProjectName(id) {
    try {
      const response = await axiosInstance.delete(`/project/delete/${id}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async updateProject(projectdetail) {
    try {
      const response = await axiosInstance.put('/project/update', projectdetail);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getTop5XMLReport() {
    try {
      const response = await axiosInstance.get('/qa/getTop5XMLReport');
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getTop5XMLReportByCurrentReport(reportName) {
    try {
      const response = await axiosInstance.get(`/qa/top10XMLReportByReportName?reportName=${encodeURIComponent(reportName)}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async top10SeleniumHtmlComparisonGraphReportByReportName(reportName) {
    try {
      const response = await axiosInstance.get(`/qa/top10SeleniumHtmlComparisonGraphReportByReportName?reportName=${encodeURIComponent(reportName)}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getTop5PlayWrightReportByCurrentReport(reportName) {
    try {
      const response = await axiosInstance.get(`/playwright/top10PlaywrightComparisonGraphReportByReportName?reportName=${encodeURIComponent(reportName)}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getTop5CypressReportByCurrentReport(reportName) {
    try {
      const response = await axiosInstance.get(`/cypress/top10ComparisonGraphReportByReportName?reportName=${encodeURIComponent(reportName)}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getTop5CypressReportByTestReportId(id) {
    try {
      const response = await axiosInstance.get(`/cypress/topFiveComparisonGraphReportByReportId?reportId=${id}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getCummilitiveBuildRunData(reportData) {
    try {
      const response = await axiosInstance.get(`/qa/getTop10XMLReportSummary?reportName=${encodeURIComponent(reportData.reportName)}&companyId=${reportData.companyId}&projectId=${reportData.projectId}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getCummilitiveHTMLBuildRunData(reportData) {
    try {
      const response = await axiosInstance.get(`/qa/getTop10HTMLReportSummary?reportName=${encodeURIComponent(reportData.reportName)}&companyId=${reportData.companyId}&projectId=${reportData.projectId}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getCummilitivePlayBuildRunData(reportData) {
    try {
      const response = await axiosInstance.get(`/playwright/getTop10ReportSummary?reportName=${encodeURIComponent(reportData.reportName)}&companyId=${reportData.companyId}&projectId=${reportData.projectId}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getCummilitiveCypressBuildRunData(reportData) {
    try {
      const response = await axiosInstance.get(`/cypress/getTop10ReportSummary?reportName=${encodeURIComponent(reportData.reportName)}&companyId=${reportData.companyId}&projectId=${reportData.projectId}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getTopFiveReportsGraph() {
    try {
      const response = await axiosInstance.get('/playwright/getTopFiveReportsGraph');
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getTopFiveCypressReportsGraph() {
    try {
      const response = await axiosInstance.get('/cypress/getTopFiveReportsGraph');
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getTop5PlayWrightReportByTagNameGraph(payload) {
    try {
      const response = await axiosInstance.get(`/playwright/getWeeklyTopFiveReportsGraph?endDate=${payload?.endDate}&reportName=${encodeURI(payload.reportName)}&startedAt=${payload?.startDate}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getTop5CypressReportByTagNameGraph(payload) {
    try {
      const response = await axiosInstance.get(`/cypress/getWeeklyTopFiveReportsGraph?endDate=${payload?.endDate}&reportName=${encodeURI(payload.reportName)}&startedAt=${payload?.startDate}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getTop5ReportByTagNameGraph(payload) {
    try {
      const response = await axiosInstance.get(`/qa/getTop5ReportByTagNameGraph?endDate=${payload?.endDate}&reportName=${encodeURI(payload.reportName)}&startedAt=${payload?.startDate}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getProjectinviteList() {
    try {
      const response = await axiosInstance.get('/projectInvite/getProjectListByCurrentUser');
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async deleteReportById(fileType, reportId) {
    try {
      const response = await axiosInstance.delete(`/dashboard/deleteReportById?fileType=${fileType}&reportId=${reportId}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async downloadPdf(path, reportName, reportKey) {
    try {
      const response = await axiosInstance.get(`/user/getAutomationReport/${path}/${reportName}/${reportKey}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }
}
export default new ReportService();
