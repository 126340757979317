import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    borderBottom: `3px solid ${theme.palette.warning.main}`,
    borderLeft: `3px solid ${theme.palette.warning.main}`,
    padding: '45px',
  },
  '& .MuiDialogTitle-root': {
    fontWeight: 600,
    fontSize: '24px',
  },
}));

const DeleteReportModal = ({
  open, handleClose, onConfirm, reportName,
}) => (
  <StyledDialog open={open} onClose={handleClose}>
    <DialogTitle>
      Confirm Deletion
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure you want to delete the report
        { reportName }
        ? This action cannot be undone.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onConfirm} color="error">
        Delete
      </Button>
    </DialogActions>
  </StyledDialog>
);

DeleteReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  reportName: PropTypes.string.isRequired,
};

export default DeleteReportModal;
