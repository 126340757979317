import axiosInstance from './axiosInstance';
import CommonUtils from '../utils/common/common';

const rootURL = '/qa';

const uploadAsset = async (file) => {
  try {
    const formData = new FormData();
    formData.append('image', file);
    const response = await axiosInstance.post('/user/uploadImage/REVIEW_IMAGE', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const uploadFile = async (data, cid, pid, onUploadProgress) => {
  try {
    const response = await axiosInstance.post(`${rootURL}/report-upload?companyId=${cid}&projectId=${pid}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (udata) => {
        onUploadProgress(udata);
      },
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const resultList = async (page, size, searchKeyword) => {
  try {
    const response = await axiosInstance.get(`${rootURL}/xmlResultsByCurrentUserSearchPageable?pageNumber=${page}&pageSize=${size}&search=${searchKeyword}`);
    if (CommonUtils.isValidReportResonse(response.data)) {
      const { data } = response;
      const filtered = data.content.filter((r) => CommonUtils.isValidReport(r));
      return { ...data, content: filtered };
    }
    return { content: [], totalElements: 0 };
  } catch (err) {
    throw err.response;
  }
};

const seleniumTestResultList = async (query) => {
  try {
    const response = await axiosInstance.get(`${rootURL}/xmlResultsByCurrentUserSearchPageable?${query}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const seleniumHTMLTestResultList = async (query) => {
  try {
    const response = await axiosInstance.get(`${rootURL}/htmlResultPageByCurrentUser?${query}`);

    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const playwrightTestResultList = async (query) => {
  try {
    const response = await axiosInstance.get(`/playwright/playwrightReportPageByCurrentUser?${query}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const cypressTestResultList = async (query) => {
  try {
    const response = await axiosInstance.get(`/cypress/reportPageableResponseByCurrentUser?${query}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const playwrightResultList = async (page, size, searchKeyword) => {
  try {
    const response = await axiosInstance.get(`/playwright/playwrightReportPageByCurrentUser?pageNumber=${page}&pageSize=${size}&search=${searchKeyword}`);
    if (CommonUtils.isValidReportResonse(response.data)) {
      const { data } = response;
      const filtered = data.content.filter((r) => CommonUtils.isValidPlaywrightReport(r));
      return { ...data, content: filtered };
    }
    return { content: [], totalElements: 0 };
  } catch (err) {
    throw err.response;
  }
};
const cypressResultList = async (page, size, searchKeyword) => {
  try {
    const response = await axiosInstance.get(`/cypress/reportPageableResponseByCurrentUser?pageNumber=${page}&pageSize=${size}&search=${searchKeyword}`);

    if (CommonUtils.isValidReportResonse(response.data)) {
      const { data } = response;
      const filtered = data.content.filter((r) => CommonUtils.isValidCypressReport(r));
      return { ...data, content: filtered };
    }
    return { content: [], totalElements: 0 };
  } catch (err) {
    throw err.response;
  }
};
const playwrightListByCurrentUser = async () => {
  try {
    const response = await axiosInstance.get('/playwright/distinctReportNameListByCurrentUser');
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const cypressListByCurrentUser = async () => {
  try {
    const response = await axiosInstance.get('/cypress/distinctReportNameListByCurrentUser');
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const resultListById = async (id) => {
  try {
    const response = await axiosInstance.get(`${rootURL}/xmlResultById/${id}`);
    return response;
  } catch (err) {
    throw err.response;
  }
};
const resultListHTMLById = async (id) => {
  try {
    const response = await axiosInstance.get(`${rootURL}/htmlResultById/${id}`);
    return response;
  } catch (err) {
    throw err.response;
  }
};

const playwrightResultListById = async (id) => {
  try {
    const response = await axiosInstance.get(`/playwright/playwrightReportById/${id}`);
    return response;
  } catch (err) {
    throw err.response;
  }
};

const cypressResultListById = async (id) => {
  try {
    const response = await axiosInstance.get(`/cypress/reportById/${id}`);
    return response;
  } catch (err) {
    throw err.response;
  }
};

const testReportInvite = async (page, size) => {
  try {
    const response = await axiosInstance.get(`/testReportInvite/xmlReportPageByCurrentInvitedUser?pageNumber=${page}&pageSize=${size}`);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const htmlReportInvite = async (page, size) => {
  try {
    const response = await axiosInstance.get(`/testReportInvite/htmlReportPageByCurrentInvitedUser?pageNumber=${page}&pageSize=${size}`);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getListByUserId = async () => {
  try {
    const response = await axiosInstance.get(`${rootURL}/xmlReportNameListByCurrentUser`);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getListByHtmlUserId = async () => {
  try {
    const response = await axiosInstance.get(`${rootURL}/distinctSeleniumHtmlReportNameListByCurrentUser`);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const htmlReports = async (page, size) => {
  try {
    const response = await axiosInstance.get(`${rootURL}/htmlResultPageByCurrentUser?pageNumber=${page}&pageSize=${size}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const getHtmlListByUserId = async () => {
  try {
    const response = await axiosInstance.get(`${rootURL}/htmlResultListByCurrentUser`);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};
const getUploadedFile = async () => {
  let data;
  // await axios.get(`${rootURL}/uploadedFiles`).then((fileData) => {
  //   data = fileData;
  // }).catch((err) => {
  //   throw new Error(err);
  // });
  return data;
};
const deleteReport = async (id) => {
  try {
    const response = await axiosInstance.delete(`${rootURL}/deleteById/${id}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const downloadPdf = async (payload) => {
  try {
    const response = await axiosInstance.post('/public/downloadPdf', payload, { responseType: 'blob' });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const exportToExcel = async (generateExcelRequest) => {
  try {
    const response = await axiosInstance.post('/dashboard/excel', generateExcelRequest, { responseType: 'blob' });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
export {
  uploadFile,
  getUploadedFile,
  resultList,
  getListByUserId,
  deleteReport,
  htmlReports,
  getHtmlListByUserId,
  downloadPdf,
  testReportInvite,
  uploadAsset,
  htmlReportInvite,
  exportToExcel,
  resultListById,
  seleniumHTMLTestResultList,
  playwrightResultList,
  playwrightListByCurrentUser,
  cypressResultList,
  cypressListByCurrentUser,
  seleniumTestResultList,
  playwrightTestResultList,
  cypressTestResultList,
  cypressResultListById,
  playwrightResultListById,
  resultListHTMLById,
  getListByHtmlUserId,
};
