import axiosInstance from './axiosInstance';

const aiAssistant = async (file, exception) => {
  try {
    const formData = new FormData();
    formData.append('image', file, 'screenshot.jpeg');
    formData.append('queryContent', exception);
    const response = await axiosInstance.post('/aiAssistant/aiAssistant', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
export default aiAssistant;
