import axiosInstance from './axiosInstance';

class ProjectService {
  async getUsersByProject(id) {
    try {
      const response = await axiosInstance.get(`/projectInvite/getMemberList/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getProjectListByUser(pageNumber = 0, pageSize = 10) {
    try {
      const response = await axiosInstance.get(`/projectInvite/getProjectListByCurrentUser?pageNumber=${pageNumber}&pageSize=${pageSize}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getProjectById(pid, page = 0, size = 10) {
    try {
      const response = await axiosInstance.get(`/projectInvite/getProject/${pid}?pageNumber=${page}&pageSize=${size}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async inviteForProject(payload) {
    try {
      const response = await axiosInstance.post('/projectInvite/send', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async acceptProjectInvite(projectId) {
    try {
      const response = await axiosInstance.put(`/projectInvite/accept/${projectId}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getMembers(companyId, projectId, pageNumber, pageSize) {
    try {
      const response = await axiosInstance.get(`/projectInvite/getMembers?companyId=${companyId || ''}&projectId=${projectId || ''}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getMemberList(companyId, projectId) {
    try {
      const response = await axiosInstance.get(`projectInvite/getMemberList/${companyId}/${projectId}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async blockProjectForUser(projectId, payload) {
    try {
      const response = await axiosInstance.patch(`projectInvite/block/${projectId}`, payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async blockProject(payload) {
    try {
      const response = await axiosInstance.patch('/project/update/status', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getProjectRequestWaitingList() {
    try {
      const response = await axiosInstance.post('/send/email/projectWaitingListRequest');
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async projectWaitingRequest() {
    try {
      const response = await axiosInstance.get('/projectInvite/projectWaitingRequest');
      return response.status;
    } catch (err) {
      throw new Error(err);
    }
  }
}
export default new ProjectService();
