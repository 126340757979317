import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tabs,
  Tab,
  Avatar,
  Box,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CommentIcon from '@mui/icons-material/Comment';
import Editor from '../Editor';
import commentService from '../../services/comment.service';
import { UserRole } from '../../utils/constants';
import { useCommonContext } from '../header/context';
import { parseDate } from '../dashboard/utils';
import { getAllJiraCommentsByIssueId } from '../../services/integrations';
import CommonUtils from '../../utils/common/common';

const useStyles = makeStyles(() => ({
  buttonSave: {
    backgroundColor: '#1d6ae5',
    boxShadow: 'none',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#165bb5',
    },
  },
}));

const commentWithInlineStyle = (comment) => {
  const imageStyle = 'max-width: 460px; height: auto; margin-bottom: 10px;';
  const textStyle = 'font-size: 14px; color: #333; font-family: graphik_regularregular; font-weight: 600;';
  let modifiedComment = comment?.replaceAll('<img', `<img style="${imageStyle}"`);
  modifiedComment = modifiedComment ? `<div style="${textStyle}">${modifiedComment}</div>` : '';

  return modifiedComment;
};

const JiraCommentsList = ({ comments }) => <>
  <Box width="100%" maxHeight={500} overflow="auto">
    {comments?.map((comment) => {
      const commentText = comment.comment;
      const avatar = comment.avatarUrl;
      const name = comment.displayName;
      const createdAt = comment.createAt;

      return (
        commentText && (
          <List
            sx={{
              bgcolor: 'background.paper',
              width: '100%',
            }}
          >
            <ListItem alignItems="flex-start">
              <Tooltip title={name}>
                <Avatar sx={{ mr: 2 }} alt={name} src={avatar} />
              </Tooltip>
              <ListItemText
                sx={{ mt: 0 }}
                primary={
                  <div dangerouslySetInnerHTML={{ __html: commentText }} />
                }
                secondary={
                  <>
                    <Typography
                      sx={{ display: 'inline', color: '#5a5a5a' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {parseDate(createdAt)}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </List>
        )
      );
    })}
  </Box>
</>;

JiraCommentsList.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      comment: PropTypes.string,
      createAt: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
  ),
};

JiraCommentsList.defaultProps = {
  comments: [],
};
const CommentsList = ({ comments }) => (
  <div style={{ width: '100%', maxHeight: '500px', overflow: 'auto' }}>
    {comments?.comment?.map((comment) => {
      const commentText = commentWithInlineStyle(comment?.comment);
      return (commentText && <List
        sx={{
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <ListItem className="last-option" alignItems="flex-start">
          <Tooltip title={comment?.userResponse?.username}>
            <Avatar sx={{ mr: 2 }} alt="helo" src={comment?.userResponse?.image} />
          </Tooltip>
          <ListItemText
            sx={{ mt: 0 }}
            primary={
              <div dangerouslySetInnerHTML={{ __html: commentText }} />
            }
            secondary={
              <>
                <Typography
                  sx={{ display: 'inline', color: '#5a5a5a' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {parseDate(comment?.createAt)}
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
      );
    })}
  </div>
);

CommentsList.propTypes = {
  comments: PropTypes.objectOf({
    comment: PropTypes.arrayOf(),
    createdAt: PropTypes.string,
  }),
};

CommentsList.defaultProps = {
  comments: {},
};

const CommentWrapper = ({
  selectedTestName,
  testReportId,
  setComments,
  comments,
  loading,
  setLoading,
  setCommentText,
  commentText,
  selectedChip,
  guestUser,
  setSelectedChip,
  jiraTickets,
}) => {
  const { user } = useCommonContext();
  const classes = useStyles();
  const isAdminOrSuperAdmin = user?.role?.roleName === UserRole.SUPER_ADMIN
    || user?.role?.roleName === UserRole.ADMIN;
  const [value, setValue] = useState(0);
  const [ticketId, setTicketId] = useState('');
  const [jiraComment, setJiraComment] = useState([]);

  const loadComments = () => {
    // setLoading(true);
    commentService.getAll({
      reportName: CommonUtils.replaceAmpersandWithAnd(selectedTestName),
      id: testReportId,
    }).then((res) => {
      setSelectedChip(res.tagName || 'TO_INVESTIGATE');
      if (res && res.comment) {
        const sortedComments = res.comment.reverse();
        setComments({ comment: sortedComments });
      } else {
        setComments([]);
        console.error('Invalid response format:', res);
      }
      setLoading(false);
    }).catch((error) => {
      console.error('Error loading comments:', error);
      setLoading(false);
    });
  };

  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  const extractData = (item) => {
    const displayName = item.author?.displayName || '';
    const comment = item.body?.content[0]?.content[0]?.text || '';
    const createAt = item.created || '';
    const avatarUrl = item.author?.avatarUrls?.['48x48'] || '';

    return {
      displayName,
      comment,
      createAt,
      avatarUrl,
    };
  };

  const jiraComments = async () => {
    try {
      let projectName = '';
      if (jiraTickets && ticketId) {
        projectName = jiraTickets.find((item) => item.jiraTicketId === ticketId)?.projectName;
        const res = await getAllJiraCommentsByIssueId({ jiraTicketId: ticketId, projectName });
        if (res) {
          const result = res?.comments.reverse().map(extractData);
          setJiraComment(result);
        }
      } else {
        throw new Error('No Ticket Found');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createComment = () => {
    setLoading(true);

    const nonHtmlText = stripHtmlTags(commentText);

    if (!nonHtmlText.trim().length) {
      setCommentText('');
      setLoading(false);
      return;
    }
    let projectName = '';
    if (jiraTickets) {
      projectName = jiraTickets.find((item) => item.jiraTicketId === ticketId)?.projectName;
    }

    const commentData = {
      testName: CommonUtils.replaceAmpersandWithAnd(selectedTestName),
      testReportId,
      comment: value === 0 ? commentText : nonHtmlText,
      tagName: selectedChip,
      jiraTicketId: ticketId || '',
      projectName: projectName || '',
    };

    commentService.create(commentData)
      .then(() => {
        loadComments();
        jiraComments();
        setCommentText('');
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadComments();
  }, [selectedTestName]);

  useEffect(() => {
    if (value === 1 && jiraTickets && ticketId) {
      jiraComments();
    }
  }, [value, ticketId]);

  useEffect(() => {
    if (jiraTickets) setTicketId(jiraTickets[0]?.jiraTicketId);
  }, [jiraTickets]);

  if (!isAdminOrSuperAdmin
    && comments?.comment?.length === 0
    && !loading
    && !guestUser
  ) return <></>;

  return (
    <Grid container>
      {
        isAdminOrSuperAdmin && <Grid
          item
          xs={12}
          py={2}
        >
          <Grid container>
            {jiraTickets.length > 0 && <Grid item xs={12}>
              <Tabs value={value} onChange={(e, val) => setValue(val)} aria-label="basic tabs example">
                <Tab sx={{ textTransform: 'capitalize', fontWeight: 'bold' }} iconPosition="start" label="Test Comment" />
                <Tab sx={{ textTransform: 'capitalize', fontWeight: 'bold' }} iconPosition="start" label="Jira Comment" />
              </Tabs>
            </Grid>}
            <Grid item xs={12} sx={{ my: 1 }}>
              <Editor
                width="40"
                content={commentText}
                onUpdate={setCommentText}
              />
            </Grid>

            <Grid item xs={12} mt={1} textAlign="end">
              <Button
                disabled={loading || (value === 1 && !ticketId)}
                variant="contained"
                className={classes.buttonSave}
                onClick={createComment}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
      <List sx={{ width: '100%', mt: 2, pb: 0 }}>
        <ListItem>
          <ListItemAvatar sx={{ minWidth: 'auto', mr: 1 }}>
            <CommentIcon sx={{ color: '#1d6ae5' }} fontSize="large" />
          </ListItemAvatar>
          <ListItemText fontWeight="bold" primary="Comments" />
        </ListItem>
      </List>
      {value === 0 && <CommentsList comments={comments} />}
      {value === 1 && <JiraCommentsList comments={jiraComment} />}
    </Grid>
  );
};

CommentWrapper.propTypes = {
  selectedTestName: PropTypes.string,
  testReportId: PropTypes.string,
  loading: PropTypes.bool,
  setComments: PropTypes.func,
  comments: PropTypes.objectOf({
    comment: PropTypes.arrayOf(),
    createdAt: PropTypes.string,
  }),
  setLoading: PropTypes.func,
  setCommentText: PropTypes.func,
  commentText: PropTypes.string,
  selectedChip: PropTypes.string,
  guestUser: PropTypes.bool,
  setSelectedChip: PropTypes.func,
  jiraTickets: [],
};

CommentWrapper.defaultProps = {
  selectedTestName: '',
  testReportId: '',
  loading: false,
  setComments: () => '',
  comments: {},
  setLoading: () => false,
  setCommentText: () => '',
  commentText: '',
  selectedChip: '',
  guestUser: false,
  setSelectedChip: () => '',
  jiraTickets: [],
};

export default CommentWrapper;
