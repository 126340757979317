/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Backdrop, Box, CircularProgress, Typography,
} from '@mui/material';
import { keyframes } from '@mui/system';
import PropTypes from 'prop-types';

const waveAnimation = keyframes`
  0%, 60%, 100% { transform: translateY(0); }
  30% { transform: translateY(-10px); }
`;

export default function AnalyzingLoader({ open }) {
  const text = 'Analyzing...';

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      open={open}
    >
      <Box display="flex" alignItems="center" flexDirection="column">
        <CircularProgress color="inherit" />
        <Box display="flex" marginTop={2}>
          {text.split('')?.map((letter, index) => (
            <Typography
              key={index}
              variant="h4"
              sx={{
                fontSize: '2rem',
                animation: `${waveAnimation} 1.5s ease-in-out infinite`,
                animationDelay: `${index * 0.1}s`,
              }}
            >
              {letter}
            </Typography>
          ))}
        </Box>
      </Box>
    </Backdrop>
  );
}

AnalyzingLoader.propTypes = {
  open: PropTypes.bool,
};

AnalyzingLoader.defaultProps = {
  open: false,
};
