import React from 'react';
import {
  Box, Button, Container, Typography, Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import route from '../utils/route';

const NotFound = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/${route.ROUTE_LOGIN}`);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="md">
        <Grid container justifyContent="center" spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">
              404
            </Typography>
            <Typography variant="h6">
              The page you’re looking for doesn’t exist.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleBack}>
              Back
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>);
};
export default NotFound;
