import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  CssBaseline,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Pagination,
} from '@mui/material';
import { Container, styled } from '@mui/system';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import projectService from '../../services/project.service';
import companyService from '../../services/company.service';
import { useCommonContext } from '../header/context';
import reportService from '../../services/report.service';
import { projectStyles } from '../header/style';
import { UserRole } from '../../utils/constants';
import route from '../../utils/route';

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  border: '0',
  '& .MuiTablePagination-toolbar': {
    padding: '0',
    width: '100%',
  },
  '& .MuiPagination-root': {
    width: '50%',
  },
  '& .MuiTablePagination-input': {
    border: '1px solid #e3e3e3',
    borderRadius: '4px',
    marginRight: theme.spacing(0),
  },
  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
    margin: theme.spacing(0, 2),
  },
  '& .MuiTablePagination-actions': {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPagination-ul': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 'max-content',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: '8px',
    margin: theme.spacing(0.5),
    padding: '8px 12px',
    border: '1px solid #e0e0e0',
    '&.Mui-selected': {
      backgroundColor: '#1976d2', // Primary color for selected page
      color: '#fff',
      fontWeight: 'bold',
    },
    '&:hover': {
      color: '#000',
      backgroundColor: '#f5f5f5', // Hover effect for buttons
    },
  },
  '& .MuiPaginationItem-ellipsis': {
    border: 'none', // No border for ellipsis
    background: 'none',
  },
  '& .MuiPaginationItem-previousNext': {
    minWidth: '32px', // Ensure uniform size for the previous/next buttons
  },
}));

function Team() {
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState('');
  const [projectId, setProjectId] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [projectDropdown, setProjectDropdown] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const selectRef = useRef(null);
  const [selectKey, setSelectKey] = useState(0);
  const [isClearing, setIsClearing] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = projectStyles();

  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const {
    user,
    setActiveItem,
  } = useCommonContext();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(1);
  };

  const handleChange = (event) => {
    setCompanyId(event?.target?.value);
    setPage(1);
    setRowsPerPage(10);
    setProjectId('');
  };
  const handleChangeProject = (event) => {
    setProjectId(event?.target?.value);
  };

  const getProjectsList = async () => {
    if (companyId) {
      try {
        const res = await reportService.getProjectById(companyId);
        setProjectDropdown(res);
      } catch (error) {
        setProjectDropdown([]);
      }
    }
  };

  const getMembers = async () => {
    try {
      setLoading(true);
      const res = await projectService.getMembers(companyId, projectId, page > 0
        ? page - 1 : 0, rowsPerPage);
      if (res?.content?.length) {
        setTeamMembers(res.content);
      } else {
        setTeamMembers([]);
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: 'No Team Member found for this project',
        });
      }
      setTotalPages(res?.totalPages);
    } catch (err) {
      setTeamMembers([]);
      console.log('err', err);
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: 'Something went wrong',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjectsList();
  }, [user?.role?.roleId, companyId]);

  const companyList = async () => {
    try {
      const res = await companyService.getAll();
      setCompanyDropdown(res);
    } catch (error) {
      setCompanyDropdown([]);
    }
  };

  useEffect(() => {
    if (user?.role?.roleName === UserRole.ADMIN || user?.role?.roleName === UserRole.QA_MANAGER) {
      setCompanyId(user?.company?.companyId);
      setProjectId(user?.company?.projectResponses[0].projectId);
    }
    companyList();
    if (user?.role?.roleName === UserRole.SUPER_ADMIN) {
      getMembers();
    }
  }, [user?.role?.roleId, page, rowsPerPage]);

  useEffect(() => {
    if (companyId && projectId) {
      getMembers();
    }
    if (isClearing && companyId === '' && projectId === '') {
      getMembers();
      setIsClearing(false);
    }
  }, [projectId, companyId, isClearing, page, rowsPerPage]);

  const handleClear = () => {
    setIsClearing(true);
    setCompanyId('');
    setProjectId('');
    setSelectKey((prevKey) => prevKey + 1);
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  useEffect(() => {
    setActiveItem('Team');
  }, []);

  return (
    <>
      <Container maxWidth={false} className={classes.root}>
        <CssBaseline />
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" marginBottom={2}>
          <Typography color="#666666" onClick={() => navigate(`/${route.ROUTE_DASHBOARD}`)} sx={{ cursor: 'pointer' }}>Dashboard</Typography>
          <Typography color="#666666">Team</Typography>
        </Breadcrumbs>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h4">
            Team
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={4}>
            <Paper className={classes.summaryBox}>
              <Box className={classes.summaryBoxInTeam}>
                <Grid item xs={12}>
                  <Grid
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap={4}
                    sx={{
                      py: 4,
                      px: 1.5,
                      marginBottom: 2,
                      borderBottom: '1px solid #e2e5e8',
                    }}
                  >
                    <FormControl style={{ width: '300px' }}>
                      <InputLabel size="small">Select Company</InputLabel>
                      <Select
                        ref={selectRef}
                        key={selectKey}
                        size="small"
                        value={companyId || ''}
                        label="Select Company"
                        onChange={handleChange}
                        sx={{ borderRadius: '20px' }}
                      >
                        {companyDropdown?.length ? companyDropdown?.map((menuitem) => (
                          <MenuItem value={menuitem?.companyId} key={menuitem?.companyId}>
                            {menuitem?.companyName}
                          </MenuItem>
                        )) : <MenuItem style={{ color: '#999' }}>
                          No Company Found
                        </MenuItem>}
                      </Select>
                    </FormControl>
                    <FormControl style={{ width: '300px' }}>
                      <InputLabel size="small">Select Project</InputLabel>
                      <Select
                        ref={selectRef}
                        key={selectKey}
                        size="small"
                        value={projectId || ''}
                        label="Select Project"
                        onChange={handleChangeProject}
                        sx={{ borderRadius: '20px' }}
                      >
                        {projectDropdown?.length ? projectDropdown?.map((menuitem) => (
                          <MenuItem value={menuitem?.projectId} key={menuitem?.projectId}>
                            {menuitem?.projectName}
                          </MenuItem>
                        )) : <MenuItem style={{ color: '#999' }}>
                          No Project Found
                        </MenuItem>}
                      </Select>
                    </FormControl>
                    <FormControl>
                      {(projectId && user?.role?.roleName === UserRole.SUPER_ADMIN) && <Box display="flex" justifyContent="flex-end" mx={0} alignItems="center">
                        <Typography onClick={handleClear} style={{ fontSize: '13px', color: '#393939', cursor: 'pointer' }}>
                          Clear All
                        </Typography>
                      </Box>}
                    </FormControl>
                  </Grid>
                </Grid>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.headerCell}>Avatar</TableCell>
                        <TableCell className={classes.headerCell}>User Name</TableCell>
                        <TableCell className={classes.headerCell}>Role</TableCell>
                        <TableCell className={classes.headerCell}>Email</TableCell>
                        <TableCell className={classes.headerCell}>Invitor</TableCell>
                        <TableCell className={classes.headerCell}>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? <>
                        <TableRow>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                        </TableRow>
                      </> : <>
                        {!!teamMembers?.length && teamMembers?.map((member, index) => (
                          <TableRow key={`${index + 0}`}>
                            <TableCell><Avatar src={member?.invitedTo?.image} /></TableCell>
                            <TableCell>
                              <Typography variant="body1">{`${member?.invitedTo?.firstName} ${member?.invitedTo?.lastName}`}</Typography>
                            </TableCell>
                            <TableCell>{member?.invitedTo?.role?.roleName}</TableCell>
                            <TableCell>{member?.invitedTo?.email}</TableCell>
                            <TableCell>{member?.invitedById?.username}</TableCell>
                            <TableCell>{member?.projectInviteStatus}</TableCell>
                          </TableRow>
                        ))}
                      </>}
                    </TableBody>
                  </Table>
                  {totalPages ? (
                    <Box className={classes.paginationContainer}>
                      <StyledTablePagination
                        count={totalPages * rowsPerPage}
                        totalPages={totalPages}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        ActionsComponent={() => <CustomPagination
                          count={totalPages}
                          page={page}
                          onChange={handleChangePage}
                          variant="outlined"
                          shape="rounded"
                          showFirstButton
                          showLastButton
                        />}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Box>
                  ) : <></>}
                </TableContainer>
                {!loading && teamMembers.length === 0
                  && <Typography textAlign="center" variant="subtitle1" color="textSecondary">
                    No Team Member found for this project
                  </Typography>}
              </Box>
            </Paper>
          </Grid>
        </Grid>
        {
          snackbar.state
          && (
            <NavigateSnackbar
              snackbarObj={snackbar}
              setSnackbar={setSnackbar}
            />
          )
        }
      </Container>
    </>
  );
}

export default Team;
