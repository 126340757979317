import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  useTheme,
  useMediaQuery,
  Grid,
  Button,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Home as HomeIcon,
  Apartment as ApartmentIcon,
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  Group as GroupIcon,
} from '@mui/icons-material';
import UploadIcon from '@mui/icons-material/Upload';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useCommonContext } from '../header/context';
import route from '../../utils/route';
import SeleniumLogo from '../../assets/images/framework1.svg';
import PlaywrightLogo from '../../assets/images/framewor2.svg';
import CypressLogo from '../../assets/images/framewor3.svg';
import { useStyles } from '../header/style';
import {
  DashboardEnum, FileType, UserPermission, UserRole,
} from '../../utils/constants';
import UploadReport from '../UploadReport/UploadReport';

const NewSidePanel = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    user, toggleHeader, setToggleHeader, activeItem, setActiveItem, setReportType,
  } = useCommonContext();
  // const [project, setProject] = useState(
  //   user?.company?.projectResponses ? user.company.projectResponses[0]?.projectId : '',
  // );
  const [openDashBoard, setOpenDashboard] = useState(true);
  // const [openInvitedProjects, setOpenInvitedProjects] = useState(true);
  const [anchorUploadUser, setAnchorUploadUser] = useState({
    show: false,
    uploaded: false,
  });
  const handleClickOpen = () => {
    setAnchorUploadUser({ ...anchorUploadUser, show: true });
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const handleProjectChange = (event) => {
  //   setProject(event.target.value);
  // };
  // const dashboardRoutes = {
  //   SELENIUM: route.REPORT_LIST,
  //   PLAY_WRIGHT: route.PLAYWRIGHT_LIST,
  //   CYPRESS: route.CYPRESS_LIST,
  // };
  // eslint-disable-next-line no-unused-vars
  // const selectedRoute = dashboardRoutes[user?.framework[0]];
  const dashboardConfig = user?.role?.roleName !== UserRole.SUPER_ADMIN
    ? {
      label: 'Dashboard',
      icon: <DashboardIcon />,
      route: route.ROUTE_DASHBOARD,
      key: 'Dashboard',
      validatePermission: (permissions = []) => (permissions),
    }
    : {
      label: 'Dashboards',
      icon: <DashboardIcon />,
      collapse: true,
      open: openDashBoard,
      handleToggle: () => setOpenDashboard((prev) => !prev),
      validatePermission: (permissions = []) => (permissions),
      subItems: [
        {
          label: 'Selenium',
          logo: SeleniumLogo,
          key: FileType.SELENIUM,
          route: route.ROUTE_DASHBOARD,
          validatePermission: (permissions = []) => (permissions.includes(DashboardEnum.SELENIUM)),
        },
        {
          label: 'Playwright',
          logo: PlaywrightLogo,
          key: FileType.PLAYWRIGHT,
          route: route.ROUTE_DASHBOARD,
          validatePermission:
            (permissions = []) => (permissions.includes(DashboardEnum.PLAYWRIGHT)),
        },
        {
          label: 'Cypress',
          logo: CypressLogo,
          key: FileType.CYPRESS,
          route: route.ROUTE_DASHBOARD,
          validatePermission: (permissions = []) => (permissions.includes(DashboardEnum.CYPRESS)),
        },
      ],
    };
  const menuItems = [
    {
      label: 'Home',
      icon: <HomeIcon />,
      route: route.ROUTE_HOME_PAGE,
      key: 'Home',
      validatePermission: (permissions = []) => (permissions),
    },
    dashboardConfig,
    {
      label: 'Projects',
      icon: <AccountTreeIcon />,
      route: route.PROJECT_LIST,
      key: 'Projects',
      validatePermission: (permissions = []) => (permissions),
    },
    {
      label: 'Integration',
      icon: <IntegrationInstructionsIcon />,
      route: route.INTEGRATIONS,
      key: 'Integration',
      validatePermission: (permissions = []) => (permissions),
    },
    {
      label: 'Companies',
      icon: <ApartmentIcon />,
      route: route.ROUTE_COMPANIES,
      key: 'Companies',
      validatePermission: (permissions = []) => (
        permissions
        && (user?.role?.roleName === UserRole.SUPER_ADMIN
        || user?.role?.roleName === UserRole.ADMIN)
        // && user.userType === UserType.INTERNAL
      ),
    },
    // {
    //   label: 'Invited projects',
    //   collapse: true,
    //   open: openInvitedProjects,
    //   handleToggle: () => setOpenInvitedProjects((prev) => !prev),
    //   validatePermission: (permissions = []) => (permissions),
    //   customComponent: (
    //     <Select
    //       value={project}
    //       defaultValue={project}
    //       onChange={handleProjectChange}
    //       className={classes.selectBox}
    //     >
    //       {user?.company?.projectResponses && user.company.projectResponses.length > 0 ? (
    //         user.company.projectResponses.map((item) => (
    //           <MenuItem key={item.projectId} value={item.projectId}>
    //             {item?.projectName}
    //           </MenuItem>
    //         ))
    //       ) : (
    //         <MenuItem disabled>
    //           No Projects
    //         </MenuItem>
    //       )}
    //     </Select>
    //   ),
    // },
    {
      label: 'User',
      icon: <PersonIcon />,
      route: route.INVITE_USER,
      key: 'User',
      validatePermission: (permissions = []) => (
        permissions
        && (user?.role?.roleName === UserRole.SUPER_ADMIN
        || user?.role?.roleName === UserRole.ADMIN)
        // && user.userType === UserType.INTERNAL
      ),
    },
    {
      label: 'Team',
      icon: <GroupIcon />,
      route: route.TEAM,
      key: 'Team',
      validatePermission: (permissions = []) => (
        permissions.includes(UserPermission.TEAM)
      ),
    },
  ];

  const handleItemClick = (itemKey, routePath) => {
    setActiveItem(itemKey);
    if (Object.values(FileType).includes(itemKey)) {
      setReportType(itemKey === FileType.SELENIUM ? FileType.XML : itemKey);
    }
    if (routePath) {
      navigate(`/${routePath}`);
    }
  };
  useEffect(() => {
    if (isMobile) {
      setToggleHeader(false);
    }
  }, [isMobile]);

  // useEffect(() => {
  //   setProject(user?.company?.projectResponses
  //   ? user.company.projectResponses[0]?.projectId : '');
  // }, [user]);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <div className={classes.drawerContainer}>
        <List>
          {menuItems?.map((item) => {
            const isPermitted = typeof item.validatePermission === 'function' && item.validatePermission(user?.role?.userPermissionsList);
            return user && isPermitted ? (
              <React.Fragment key={item.key || item.label}>
                <ListItem
                  className={activeItem === item.key ? classes.active : classes.inActive}
                  onClick={() => {
                    if (item.collapse) item.handleToggle();
                    else handleItemClick(item.key, item.route);
                  }}
                >
                  {item.icon && (
                    <ListItemIcon className={`${classes.itemIcon} ${activeItem === item.key ? classes.activeIcon : classes.inActiveIcon}`}>
                      {item.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primary={item.label}
                    sx={{
                      '& .MuiListItemText-primary': {
                        fontWeight: activeItem === item.key ? 'bold' : 'normal',
                      },
                    }}
                  />
                  {item.collapse && (
                    <ListItemIcon className={classes.itemIcon}>
                      {item.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                  )}
                </ListItem>
                {item.collapse && (
                  <Collapse in={item.open} timeout="auto" unmountOnExit>
                    {item.customComponent}
                    {item?.subItems?.map((subItem) => {
                      const isSubPermitted = typeof subItem.validatePermission === 'function' && subItem.validatePermission(user?.framework);

                      return (
                        <React.Fragment key={typeof subItem === 'string' ? subItem : subItem.key}>
                          {typeof subItem === 'string' ? (
                            <ListItem
                              key={subItem}
                              className={classes.nested}
                              onClick={() => handleItemClick(subItem)}
                            >
                              <ListItemText
                                primary={subItem}
                                sx={{
                                  '& .MuiListItemText-primary': {
                                    fontWeight: activeItem === subItem ? 'bold' : 'normal',
                                    color: activeItem === subItem ? '#000' : '',
                                  },
                                }}
                              />
                            </ListItem>
                          ) : (
                            isSubPermitted && (
                              <ListItem
                                key={subItem.key}
                                className={`${classes.nested} ${activeItem === subItem.key ? classes.active : classes.inActive}`}
                                onClick={() => handleItemClick(subItem.key, subItem.route)}
                              >
                                <ListItemIcon className={`${classes.itemIcon} ${activeItem === subItem.key ? classes.activeIcon : classes.inActiveIcon}`}>
                                  <img src={subItem.logo} alt={subItem.label} width={20} />
                                </ListItemIcon>
                                <ListItemText
                                  primary={subItem.label}
                                  sx={{
                                    '& .MuiListItemText-primary': {
                                      fontWeight: activeItem === subItem.key ? 'bold' : 'normal',
                                      color: activeItem === subItem.key ? '#000' : '',
                                    },
                                  }}
                                />
                              </ListItem>
                            )
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Collapse>
                )}
              </React.Fragment>
            ) : null;
          })}
        </List>
      </div>
    </div>
  );

  return (
    <>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isMobile ? toggleHeader : true}
          onClose={() => setToggleHeader((prev) => !prev)}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
          <Grid display="flex" justifyContent="center" sx={{ position: 'absolute', bottom: '2%', left: '15%' }}>
            {user?.role?.userPermissionsList.includes(UserPermission.UPLOAD_REPORT) && (
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  onClick={handleClickOpen}
                  variant="contained"
                  component="span"
                  sx={{
                    backgroundColor: '#1d6ae5',
                    '&:hover': {
                      backgroundColor: '#165bb5',
                    },
                  }}
                  startIcon={<UploadIcon />}
                >
                  Upload Report
                </Button>
              </Stack>
            )}
          </Grid>
        </Drawer>
      </nav>
      {anchorUploadUser.show && <UploadReport
        setAnchorUploadUser={setAnchorUploadUser}
        anchorUploadUser={anchorUploadUser}
      />}
    </>
  );
};

export default NewSidePanel;
