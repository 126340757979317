import React, { useState, useEffect } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/system';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import reportservice from '../../services/report.service';
import route from '../../utils/route';
import { useCommonContext } from '../header/context';
import {
  ProjectStatus,
  UserPermission,
  InviteStatus,
  projectEnum,
  UserRolesOject,
  UserRole,
} from '../../utils/constants';
import userService from '../../services/user.service';
import projectService from '../../services/project.service';
import { CustomPagination, projectStyles, StyledTablePagination } from '../header/style';
import { getProjectFormattedDate } from '../dashboard/utils';
import ActionModal from '../ActionModal/ActionModal';
import ProjectModal from '../Modal/ProjectModal';
import ProjectInvite from '../ProjectInvite/ProjectInvite';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import WaitingListModal from '../ProjectInvite/WaitingListModal';

function ProjectList() {
  const {
    user,
    setNotification,
    setActiveItem,
  } = useCommonContext();
  const classes = projectStyles();
  const [loading, setLoading] = useState(false);
  const [projectsName, setProjectsName] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [totalCount, setTotalCounts] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [companysId, setCompanysId] = useState([]);
  const navigate = useNavigate();
  const [opend, setOpend] = React.useState(false);
  const [openInviteProjectModal, setOpenInviteProjectModal] = React.useState(false);
  const [eventId, setEventId] = React.useState(null);
  const [projectEdit, setProjectEdit] = useState('');
  const [projectShow, setProjectShow] = useState(false);
  // const [userList, setUserList] = React.useState([]);
  const [projectDetails, setProjectDetails] = React.useState({});
  const [currentStatus, setCurrentStatus] = useState();
  const [isDeleteProject, setIsDeleteProject] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [isEditProject, setIsEditProject] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [openWaitingListModal, setOpenWaitingListModal] = useState(false);
  const [confirmWaitingList, setConfirmWaitingList] = useState(false);

  const [snackbar, setSnackbar] = useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });

  const [formData, setFormData] = useState({
    companyId: null,
    projectName: '',
    projectStatus: ProjectStatus.ACTIVE,
    userId: user?.id,
    framework: '',
  });

  const onDelete = async (id) => {
    if (id) {
      setOpend(true);
      setIsDeleteProject(true);
      setIsBlock(false);
      setEventId(id);
    }
  };

  const onInvite = async (data) => {
    setProjectDetails({
      projectId: data?.projectId,
      companyId: data?.companyId,
      projectName: data?.projectName,
      companyName: data?.companyName,
    });
    // try {
    //   const users = await userService.getAllUsers(data?.companyId, data?.projectId);
    //   setUserList(users);
    // } catch (error) {
    //   setSnackbar({
    //     navigateText: '',
    //     severity: 'error',
    //     state: true,
    //     message: `${error.message}`,
    //   });
    // }
    setOpenInviteProjectModal(true);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (event.target.name === 'companyId') setCompanysId(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpend(false);
    setFormData({ projectName: '' });
    setProjectShow(false);
    setProjectEdit('');
  };
  const onChange = (e) => {
    const { value, name } = e.target;
    if (projectShow) {
      setProjectEdit({ ...projectEdit, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const projectInviteCurrentUser = async () => {
    try {
      const res = await reportservice.projectsInviteByCurrentUser(page > 0
        ? page - 1 : 0, rowsPerPage);
      if (res) {
        setProjectsName(res.content);
        setCompanysId(res.content);
        setNotification(res.content);
        setTotalPages(res.totalPages);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error.message}`,
      });
    }
  };

  const getAllProjects = async () => {
    try {
      setLoading(true);
      const res = await reportservice.getAllProjects(page > 0 ? page - 1 : 0, rowsPerPage);
      if (res) {
        setProjectsName(res.content);
        setCompanysId(res.content);
        setTotalPages(res.totalPages);
      }
    } catch (error) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${error?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.role?.userPermissionsList.includes(UserPermission.PROJECT)) {
      getAllProjects(page, rowsPerPage);
    } else if (user?.role?.userPermissionsList) {
      projectInviteCurrentUser(page, rowsPerPage);
    }
  }, [user?.role?.roleId, page, rowsPerPage]);

  const handleUpdateForm = async () => {
    const { projectId, projectName, framework } = projectEdit;
    try {
      setLoading(true);
      await reportservice.updateProject({ projectId, projectName, framework });
      handleClose();
      getAllProjects();
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err?.data?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const onHandleDelete = async () => {
    if (eventId) {
      try {
        await reportservice.deleteProjectName(eventId);
        getAllProjects();
        setOpend(false);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error?.data?.message}`,
        });
        setOpend(false);
      } finally {
        setOpend(false);
      }
    }
  };
  useEffect(() => {
    if (user?.role?.userPermissionsList.includes(UserPermission.PROJECT)) {
      getAllProjects();
    }
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    const fetchRoleList = async () => {
      try {
        const res = await userService.getAllRole();
        setRoleList(res);
      } catch (error) {
        setRoleList([]);
      }
    };
    if (user?.role?.userPermissionsList.includes(UserPermission.INVITE_USER)) {
      fetchRoleList();
    }
  }, [user?.role?.userPermissionsList]);

  const handleSubmitForm = async () => {
    try {
      setLoading(true);
      await reportservice.addProject(formData);
      getAllProjects();
      handleClose();
      setFormData('');
      setSnackbar({
        navigateText: '',
        severity: 'success',
        state: true,
        message: 'Project Created ..!',
      });
      setTimeout(() => {
        navigate(`/${route.PROJECT_LIST}`);
      }, 2000);
    } catch (err) {
      setSnackbar({
        navigateText: '',
        severity: 'error',
        state: true,
        message: `${err.data.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  // const editDetail = (item) => {
  //   setProjectEdit(item);
  //   setProjectShow(true);
  //   setOpen(true);
  //   setIsEditProject(true);
  // };

  const onHandleBlock = async () => {
    if (eventId) {
      const payload = {
        projectId: eventId.projectId,
        status: eventId.projectStatus === InviteStatus.ACTIVE
          ? InviteStatus.BLOCKED : InviteStatus.ACTIVE,
      };
      try {
        setLoading(true);
        const response = await projectService.blockProject(payload);
        getAllProjects();
        setSnackbar({
          navigateText: '',
          severity: 'success',
          state: true,
          message: `${response}`,
        });
        setOpend(false);
      } catch (error) {
        setSnackbar({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error.message}`,
        });
        setOpend(false);
      } finally {
        setLoading(false);
        setOpend(false);
      }
    }
  };

  const blockCompany = async (item) => {
    if (item) {
      setOpend(true);
      setIsBlock(true);
      setIsDeleteProject(false);
      setEventId(item);
      setCurrentStatus(item.projectStatus === InviteStatus.ACTIVE
        ? InviteStatus.BLOCK : InviteStatus.ACTIVE);
    }
  };

  const handleCreateModal = async () => {
    if (user?.role?.roleName === UserRolesOject.ADMIN) {
      if (projectsName?.length > 0) {
        const res = await projectService.projectWaitingRequest();
        if (res === 208) {
          setConfirmWaitingList(true);
          setOpenWaitingListModal(true);
        } else if (res === 200) {
          setOpenWaitingListModal(true);
        }
      }
    } else {
      setOpen(true);
      setIsEditProject(false);
      setProjectEdit('');
    }
  };
  useEffect(() => {
    setActiveItem('Projects');
  }, []);

  return (
    <>
      {/*
        {projectsName?.length === 0 ? '' : <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          count={totalCount}
          component="div"
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />} */}
      <Container maxWidth={false} className={classes.root}>
        <CssBaseline />
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" marginBottom={2}>
          <Typography color="#666666" onClick={() => navigate(`/${route.ROUTE_DASHBOARD}`)} sx={{ cursor: 'pointer' }}>Dashboard</Typography>
          <Typography color="#666666">Project</Typography>
        </Breadcrumbs>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h4">
            Projects
          </Typography>
          {user?.role?.userPermissionsList.includes(UserPermission.INVITE_USER) && <Button
            variant="contained"
            disabled={loading}
            className={classes.actionButton}
            onClick={handleCreateModal}
          >
            Create Projects
          </Button>}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={4}>
            <Paper className={classes.summaryBox}>
              <Box className={classes.summaryBoxIn}>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  {user?.role?.userPermissionsList.includes(UserPermission.PROJECT)
                    ? <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.headerCell}>Name</TableCell>
                          <TableCell className={classes.headerCell}>Company</TableCell>
                          <TableCell className={classes.headerCell}>Email</TableCell>
                          <TableCell className={classes.headerCell}>Framework</TableCell>
                          <TableCell className={classes.headerCell}>Health</TableCell>
                          {user?.role?.userPermissionsList.includes(UserPermission.INVITE_USER)
                            && <TableCell className={classes.headerCell}>Action</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectsName?.length ? projectsName?.map((row) => (
                          <TableRow key={row?.projectId}>
                            <TableCell>
                              <Typography variant="body1">{row?.projectName}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {`Created by ${row?.customUserResponse?.username} on ${getProjectFormattedDate(row.createAt)}`}
                              </Typography>
                            </TableCell>
                            <TableCell>{row?.companyName}</TableCell>
                            <TableCell>{row?.customUserResponse?.email}</TableCell>
                            <TableCell>{projectEnum[row?.framework]}</TableCell>
                            <TableCell>{`${row?.projectHealth}%`}</TableCell>
                            {user?.role?.userPermissionsList.includes(UserPermission.INVITE_USER)
                              && <TableCell>
                                <Box display="flex" justifyContent="flex-start">
                                  <Tooltip title="Invite User">
                                    <IconButton
                                      style={{ color: '#1D6AE5' }}
                                      disabled={row?.projectStatus === InviteStatus.BLOCKED
                                        || row.companyStatus === InviteStatus.BLOCKED}
                                      onClick={() => {
                                        onInvite(row);
                                      }}
                                    >
                                      <SupervisorAccountOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {/* <Tooltip title="Edit">
                                  <IconButton
                                    style={{color: '#1D6AE5'}}
                                    disabled={row?.projectStatus === InviteStatus.BLOCKED
                                      || row.companyStatus === InviteStatus.BLOCKED}
                                    onClick={() => editDetail(row)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip> */}
                                  {user.role.roleName === UserRole.SUPER_ADMIN && <>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        color="error"
                                        onClick={() => onDelete(row?.projectId)}
                                        disabled={row?.projectStatus === InviteStatus.BLOCKED
                                        || row.companyStatus === InviteStatus.BLOCKED}
                                      >
                                        <CancelOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Block">
                                      <IconButton
                                        style={{ color: '#FFC72B' }}
                                        onClick={() => blockCompany(row)}
                                        disabled={row.companyStatus === InviteStatus.BLOCKED
                                        || (row?.blockedBy?.length > 0 && row?.blockedBy !== user.id
                                          && !(user?.role?.userPermissionsList
                                            .includes(UserPermission.COMPANY)))}
                                      >
                                        <BlockOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </>}
                                </Box>
                              </TableCell>}
                          </TableRow>
                        )) : <TableRow>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                        </TableRow>}
                      </TableBody>
                    </Table> : <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.headerCell}>Name</TableCell>
                          <TableCell className={classes.headerCell}>Company</TableCell>
                          <TableCell className={classes.headerCell}>Email</TableCell>
                          <TableCell className={classes.headerCell}>Framework</TableCell>
                          <TableCell className={classes.headerCell}>Health</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectsName?.length ? projectsName?.map((row) => (
                          <TableRow key={row?.projectId}>
                            <TableCell>
                              <Typography variant="body1">{row?.projectName}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {`Created by ${row?.customUserResponse?.username} on ${getProjectFormattedDate(row.createAt)}`}
                              </Typography>
                            </TableCell>
                            <TableCell>{row?.companyName}</TableCell>
                            <TableCell>{row?.customUserResponse?.email}</TableCell>
                            <TableCell>{projectEnum[row?.framework]}</TableCell>
                            <TableCell>{`${row?.projectHealth}%`}</TableCell>
                          </TableRow>
                        )) : <TableRow>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                          <TableCell><Skeleton animation="wave" /></TableCell>
                        </TableRow>}
                      </TableBody>
                    </Table>}
                  {totalPages > 0 ? (
                    <Box className={classes.paginationContainer}>
                      <StyledTablePagination
                        count={totalPages * rowsPerPage}
                        totalPages={totalPages}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        ActionsComponent={() => <CustomPagination
                          count={totalPages}
                          page={page}
                          onChange={handleChangePage}
                          variant="outlined"
                          shape="rounded"
                          showFirstButton
                          showLastButton
                        />}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Box>
                  ) : <></>}
                </TableContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        {opend && (
          <ActionModal
            handleClose={handleCloseModal}
            opend={opend}
            handleAction={isDeleteProject ? onHandleDelete : onHandleBlock}
            isDelete={isDeleteProject}
            isBlock={isBlock}
            action={currentStatus}
          />
        )}
        {open && (
          <ProjectModal
            open={open}
            isEditProject={isEditProject}
            handleClose={handleClose}
            data={formData}
            onChange={onChange}
            handleSubmitForm={handleSubmitForm}
            handleChange={handleChange}
            companysId={companysId}
            handleUpdateForm={handleUpdateForm}
            projectEdit={projectEdit}
            projectShow={projectShow}
            formData={formData}
          />
        )}
        {openWaitingListModal && <WaitingListModal
          open={openWaitingListModal}
          handleClose={() => setOpenWaitingListModal(false)}
          confirmWaitingList={confirmWaitingList}
          setConfirmWaitingList={setConfirmWaitingList}
        />}
        {openInviteProjectModal && (
          <ProjectInvite
            isOpen={openInviteProjectModal}
            onClose={() => setOpenInviteProjectModal(false)}
            roleList={roleList}
            setSnackbar={setSnackbar}
            projectDetails={projectDetails}
          />
        )}
        {
          snackbar.state
          && (
            <NavigateSnackbar
              snackbarObj={snackbar}
              setSnackbar={setSnackbar}
            />
          )
        }
      </Container>
    </>
  );
}

export default ProjectList;
