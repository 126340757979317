/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import googleLogo from '../../assets/images/google-logo.svg';
import eyeIcon from '../../assets/images/eye.svg';
import eyeCloseIcon from '../../assets/images/eye-close.png';
import commonUtils from '../../utils/common/common';
import dashboardImg from '../../assets/images/Dashboard.png';
import analyticsDashboardImg from '../../assets/images/Web-Analytics-Dashboard.png';
import { getGoogleUrl } from '../../utils/google';
import framework1 from '../../assets/images/framework1.svg';
import framework2 from '../../assets/images/framewor2.svg';
import framework3 from '../../assets/images/framewor3.svg';
import userService from '../../services/user.service';
import EmailVerificationPage from '../emailVerification/EmailVerification';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { DashboardEnum } from '../../utils/constants';

const SignupPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedFramework, setSelectedFramework] = useState(DashboardEnum.SELENIUM);

  const [navigateSnackbarState, setNavigateSnackbarState] = useState({
    navigateText: '',
    severity: '',
    state: false,
    message: '',
  });
  document.body.classList.add('loginBody');
  const signupSchema = Yup.object({
    firstname: Yup.string().trim().min(3, 'First name must be at least 3 characters').required('Please enter your first name'),
    lastname: Yup.string().trim().min(3, 'Last name must be at least 3 characters').required('Please enter your last name'),
    email: Yup.string()
      .trim()
      .matches(commonUtils.validateEmail, 'Email is invalid')
      .required('Please enter your email'),
    password: Yup.string()
      .trim()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
        'Password must be at least 8 characters long, contain an uppercase letter, and a symbol',
      ),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Please confirm your password'),
    agreeTerms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
    companyName: Yup.string().trim().required('Company Name is required'),
    project: Yup.string().trim().required('Project Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmpassword: '',
      agreeTerms: false,
      companyName: '',
      project: '',
      dashboardType: '',
    },
    validationSchema: signupSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true);
        const res = await userService.userRegister({
          email: values.email,
          password: values.password,
          firstName: values.firstname,
          lastName: values.lastname,
          framework: selectedFramework || values.dashboardType,
          project: values.project,
          companyName: values.companyName,
        });
        setVerifyEmail(res);
        setEmail(values.email);
      } catch (error) {
        setNavigateSnackbarState({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${error?.data?.message || 'An error occurred'}`,
        });
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleFrameworkSelect = (framework) => {
    setSelectedFramework(framework);
    formik.setFieldValue('dashboardType', framework);
  };

  return (
    <>
      {verifyEmail ? (
        <EmailVerificationPage email={email} />
      ) : (
        <div className="container-fluid signup-main pt-3">
          <div className="row">
            <div className="col-sm-12 col-lg-6" style={{ overflow: 'auto' }}>
              <div className="left-block-details pt-50">
                <div className="logo-block-wrap">
                  <Link to="/">
                    <img src={logo} alt="logo" width="206" height="38" />
                  </Link>
                </div>

                <div className="login-detail-block">
                  <div className="login-detail-inner">
                    <div className="login-detail-block">
                      <h2 className="h2">
                        Welcome to
                        <b>
                          {' testreport '}
                        </b>
                      </h2>
                      <p className="sub-title">Enter your signup details below</p>
                      <form onSubmit={formik.handleSubmit}>
                        <button onClick={() => { window.location.href = getGoogleUrl(); }} className="google-btn" type="button">
                          <img src={googleLogo} alt="google logo" width="28" height="28" />
                          Signup with Google
                        </button>
                        <span className="or-boder-block" />
                        <div className="d-block d-sm-flex justify-content-between mt-4">
                          <div className="input-wrap input-width me-1">
                            <label className="label" htmlFor="firstname">First Name</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter your first name"
                              name="firstname"
                              id="firstname"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstname}
                            />
                            {formik.touched.firstname && formik.errors.firstname && (
                              <span className="error-msg">{formik.errors.firstname}</span>
                            )}
                          </div>
                          <div className="input-wrap input-width ms-1">
                            <label className="label" htmlFor="lastname">Last Name</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter your last name"
                              name="lastname"
                              id="lastname"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.lastname}
                            />
                            {formik.touched.lastname && formik.errors.lastname && (
                              <span className="error-msg">{formik.errors.lastname}</span>
                            )}
                          </div>
                        </div>
                        <div className="input-wrap">
                          <label className="label" htmlFor="email">Email Address</label>
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Enter your email"
                            name="email"
                            id="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <span className="error-msg">{formik.errors.email}</span>
                          )}
                        </div>
                        <div className="d-block d-sm-flex justify-content-between mb-4">
                          <div className="input-wrap input-width me-1">
                            <label className="label" htmlFor="password">Password</label>
                            <div className="password-wrap">
                              <input
                                className="form-control password-bk"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter your password"
                                name="password"
                                id="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                              />
                              <div
                                className="password-show"
                                tabIndex="0"
                                role="button"
                                onClick={handlePasswordToggle}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' || e.key === ' ') {
                                    handlePasswordToggle();
                                  }
                                }}
                              >
                                <img
                                  className="eye-show"
                                  src={showPassword ? eyeCloseIcon : eyeIcon}
                                  alt="toggle visibility"
                                  width="20"
                                  height="20"
                                />
                              </div>
                            </div>
                            {formik.touched.password && formik.errors.password && (
                              <span style={{ bottom: '-12%' }} className="error-msg">{formik.errors.password}</span>
                            )}
                          </div>
                          <div className="input-wrap input-width ms-1">
                            <label className="label" htmlFor="confirmpassword">Confirm Password</label>
                            <div className="password-wrap">
                              <input
                                className="form-control password-bk"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter your confirm password"
                                name="confirmpassword"
                                id="confirmpassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmpassword}
                              />
                              <div
                                className="password-show"
                                tabIndex="0"
                                role="button"
                                onClick={handlePasswordToggle}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' || e.key === ' ') {
                                    handlePasswordToggle();
                                  }
                                }}
                              >
                                <img
                                  className="eye-show"
                                  src={showPassword ? eyeCloseIcon : eyeIcon}
                                  alt="toggle visibility"
                                  width="20"
                                  height="20"
                                />
                              </div>
                            </div>
                            {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                              <span className="error-msg">{formik.errors.confirmpassword}</span>
                            )}
                          </div>
                        </div>
                        <div className="d-block d-sm-flex justify-content-between mt-4">
                          <div className="input-wrap input-width me-1">
                            <label className="label" htmlFor="companyName">Company Name</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Company Name"
                              name="companyName"
                              id="companyName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.companyName}
                            />
                            {formik.touched.companyName && formik.errors.companyName && (
                              <span className="error-msg">{formik.errors.companyName}</span>
                            )}
                          </div>
                          <div className="input-wrap input-width ms-1">
                            <label className="label" htmlFor="project">Project</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Project name"
                              name="project"
                              id="project"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.project}
                            />
                            {formik.touched.project && formik.errors.project && (
                              <span className="error-msg">{formik.errors.project}</span>
                            )}
                          </div>
                        </div>
                        <div className="select-framework-wrap">
                          <p>Select Framework</p>
                          <div className="select-framework-row">
                            <div
                              className={`framework-box ${selectedFramework === `${DashboardEnum.SELENIUM}` ? 'active' : ''}`}
                              onClick={() => handleFrameworkSelect(DashboardEnum.SELENIUM)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                  handleFrameworkSelect(DashboardEnum.SELENIUM);
                                }
                              }}
                              tabIndex={0}
                              role="button"
                              onBlur={() => formik.setFieldTouched('dashboardType', true)}
                            >
                              <img src={framework1} alt="framework1" width="85" height="85" />
                              <span>Selenium</span>
                            </div>
                            <div
                              className={`framework-box ${selectedFramework === `${DashboardEnum.PLAYWRIGHT}` ? 'active' : ''}`}
                              onClick={() => handleFrameworkSelect(DashboardEnum.PLAYWRIGHT)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                  handleFrameworkSelect(DashboardEnum.PLAYWRIGHT);
                                }
                              }}
                              tabIndex={0}
                              role="button"
                              onBlur={() => formik.setFieldTouched('dashboardType', true)}
                            >
                              <img src={framework2} alt="framework2" width="85" height="85" />
                              <span>Playwright</span>
                            </div>
                            <div
                              className={`framework-box ${selectedFramework === `${DashboardEnum.CYPRESS}` ? 'active' : ''}`}
                              onClick={() => handleFrameworkSelect(DashboardEnum.CYPRESS)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                  handleFrameworkSelect(DashboardEnum.CYPRESS);
                                }
                              }}
                              tabIndex={0}
                              role="button"
                              onBlur={() => formik.setFieldTouched('dashboardType', true)}
                            >
                              <img src={framework3} alt="framework3" width="85" height="85" />
                              <span>Cypress</span>
                            </div>
                          </div>
                          {formik.errors.dashboardType && formik.touched.dashboardType && (
                            <span className="error-msg">{formik.errors.dashboardType}</span>
                          )}
                        </div>
                        <div className="input-wrap-check">
                          <div className="checkbox-lecf">
                            <input
                              type="checkbox"
                              id="agreeTerms"
                              name="agreeTerms"
                              onChange={formik.handleChange}
                              checked={formik.values.agreeTerms}
                            />
                            <label htmlFor="agreeTerms">I agree to all Terms, Privacy Policy, and Fees</label>
                          </div>
                          {formik.touched.agreeTerms && formik.errors.agreeTerms && (
                            <span className="error-msg">{formik.errors.agreeTerms}</span>
                          )}
                        </div>
                        <button className="custom-btn" type="submit" disabled={formik.isSubmitting || loading}>
                          {loading ? 'Registering...' : 'Register Now'}
                        </button>
                        <p className="bottom-link-wrap">
                          Already have an account?
                          <Link to="/login" style={{ marginLeft: 8 }}>Log in</Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="right-block-details right-block-details-signup">
                <div className="login-img-right">
                  <h1 className="h1">The simplest way to manage your workforce</h1>
                  <p>Enter your credentials to access your account</p>
                  <div className="right-block-detailsimg">
                    <img className="img-ds" src={analyticsDashboardImg} alt="Dashboard" width="400" height="350" />
                    <img className="sd-img" src={dashboardImg} alt="Web-Analytics-Dashboard" width="640" height="420" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        navigateSnackbarState.state
        && (
          <NavigateSnackbar
            snackbarObj={navigateSnackbarState}
            setSnackbar={setNavigateSnackbarState}
          />
        )
      }
    </>
  );
};

export default SignupPage;
