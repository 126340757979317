import axiosInstance from './axiosInstance';

const addSlackWebHook = async (url) => {
  try {
    const response = await axiosInstance.post(`/generate/saveOrUpdateSlackWebhookUrl?slackWebhookUrl=${url}`);
    return response.status === 200 || 201;
  } catch (err) {
    throw err.response;
  }
};

const getSlackWebHook = async () => {
  try {
    const response = await axiosInstance.get('/generate/getSlackWebhookUrl');
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const deleteSlackWebHook = async () => {
  try {
    const response = await axiosInstance.delete('/generate/deleteSlackWebhookUrl');
    return response.status === 200;
  } catch (err) {
    throw err.response;
  }
};

// const reNewPluginAccessKeyToken = async () => {
//   try {
//     const response = await axiosInstance.get('/generate/reNewToken');
//     return response.status === 200;
//   } catch (err) {
//     throw err.response;
//   }
// };
const createJiraProject = async (jiraProject) => {
  try {
    const response = await axiosInstance.post('/integration-jira/saveJiraAccount', {
      ...jiraProject,
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const jiraAccountPageByCurrentUser = async (pageNumber, pageSize = 5) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/jiraAccountPageByCurrentUser?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const createJiraTicket = async (ticket) => {
  try {
    const response = await axiosInstance.post('/integration-jira/createJiraTicket', {
      ...ticket,
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getAllJiraCommentsByIssueId = async ({ jiraTicketId, projectName }) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/getJiraComments?jiraTicketId=${jiraTicketId}&projectName=${projectName}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const deleteJiraAccount = async (id) => {
  try {
    const response = await axiosInstance.delete(`/integration-jira/delete/${id}`);
    return response.status === 200;
  } catch (err) {
    throw err.response;
  }
};

const jiraAccountByProjectName = async (projectName) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/jiraAccountByProjectName?projectName=${projectName}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getAllProjectIssueType = async (projectName) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/getJiraAccountIssueType?jiraProjectName=${projectName}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const fetchJiraTickets = async (query) => {
  try {
    const response = await axiosInstance.get(`/integration-jira/jiraTicketPageByCurrentUser?${query}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export {
  addSlackWebHook,
  getSlackWebHook,
  deleteSlackWebHook,
  createJiraProject,
  jiraAccountPageByCurrentUser,
  createJiraTicket,
  jiraAccountByProjectName,
  getAllProjectIssueType,
  fetchJiraTickets,
  deleteJiraAccount,
  getAllJiraCommentsByIssueId,
  // reNewPluginAccessKeyToken,
};
