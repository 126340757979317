import { useCommonContext } from '../components/header/context';
import {
  CypressTestStatus,
  FileType, HtmlStatus, PlatwrightTestStatus, TestStatus,
} from './constants';

const useHandleChipClick = () => {
  const { reportType, setBuildRunTab, setSelectedFilter } = useCommonContext();

  const handleChipClick = (val) => {
    const targetVal = val.toLowerCase();

    switch (reportType) {
      case FileType.SELENIUM:
      case FileType.XML:
        if (targetVal === 'passed' || targetVal === 'failed') {
          setBuildRunTab(1);
          setSelectedFilter(targetVal === 'passed' ? TestStatus.PASS : TestStatus.FAIL);
        }
        break;
      case FileType.HTML:
        if (targetVal === 'passed' || targetVal === 'failed') {
          setBuildRunTab(1);
          setSelectedFilter(targetVal === 'passed' ? HtmlStatus.PASSED : HtmlStatus.FAILED);
        }
        break;

      case FileType.PLAYWRIGHT:
        if (Object.values(PlatwrightTestStatus).includes(targetVal)) {
          setSelectedFilter(targetVal);
        }
        if (targetVal === 'ignored') {
          setSelectedFilter(PlatwrightTestStatus.FLAKY);
        }
        setBuildRunTab(1);
        break;
      case FileType.CYPRESS:
        if (Object.values(CypressTestStatus).includes(targetVal)) {
          setSelectedFilter(targetVal);
        }
        if (targetVal === 'ignored') {
          setSelectedFilter(CypressTestStatus.PENDING);
        }
        setBuildRunTab(1);
        break;

      default:
        console.warn(`Unhandled reportType: ${reportType}`);
        break;
    }
  };

  return handleChipClick;
};

export default useHandleChipClick;
