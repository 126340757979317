import axios from 'axios';
import axiosInstance from './axiosInstance';
// import { reNewPluginAccessKeyToken } from './integrations';

const rootUrl = `${process.env.REACT_APP_BACKEND_BASE_URL || ''}`;
class UserService {
  async userLogin(userDetails) {
    try {
      const response = await axios.post(`${rootUrl}/auth/login`, userDetails);
      if (response.data.accessToken) {
        localStorage.setItem('token', response.data.accessToken);
        // try {
        //   await reNewPluginAccessKeyToken();
        // } catch (err) {
        //   console.error('Failed to renew plugin access token:', err);
        // }
      }
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async changePassword(payload) {
    try {
      const response = await axiosInstance.put(`${rootUrl}/user/changePassword`, payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async changePasswordBySuperAdmin(resetRequest) {
    try {
      const response = await axiosInstance.put(`${rootUrl}/user/changePasswordBySuperAdmin`, resetRequest);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async forgotPassword(payload) {
    try {
      const response = await axiosInstance.post(`${rootUrl}/auth/forgotPassword`, payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async registerUser(path, userRegister) {
    try {
      const response = await axios.post(`${rootUrl}/auth/register/${path}`, userRegister);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async userRegister(user) {
    try {
      const response = await axios.post(`${rootUrl}/auth/register`, user);
      return response.status === 200;
    } catch (err) {
      throw err.response;
    }
  }

  async verifyLink(id) {
    try {
      const response = await axios.get(`${rootUrl}/invite/verifyLink?id=${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async userInfo() {
    try {
      const response = await axiosInstance.get(`${rootUrl}/user/me`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async onboarding() {
    try {
      const response = await axiosInstance.post(`${rootUrl}/user/onboarding-finish`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getAllUsers(companyId, projectId) {
    try {
      const response = await axiosInstance.get(`/invite/getAllInvitedUser/${companyId}/${projectId}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getUserByEmail(email) {
    try {
      const response = await axiosInstance.get(`user/get/${email}`);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async assignPermissions(payload) {
    try {
      const response = await axiosInstance.put('/roles/assignPermissions', payload);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async updateRoles(payload) {
    try {
      const response = await axiosInstance.put('/roles/update', payload);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async userUpdate(userDetail) {
    try {
      const response = await axiosInstance.put('user/update', userDetail, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async getAllRole() {
    try {
      const response = await axiosInstance.get('roles/get');
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async userUpdateRole(payload) {
    try {
      const response = await axiosInstance.patch('user/updateRole', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async userSwitchCompany(payload) {
    try {
      const response = await axiosInstance.patch('user/company/switch', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }

  async updateSocialUser(socialUserUpdateRequest) {
    try {
      const response = await axiosInstance.post(`${rootUrl}/user/social-update`, socialUserUpdateRequest);
      return response.status === 200;
    } catch (err) {
      throw err.response;
    }
  }

  async emailVerification(id) {
    try {
      const response = await axios.get(`${rootUrl}/auth/login/verifyEmail?id=${id}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  }
}

export default new UserService();
