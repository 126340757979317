/* eslint-disable react/no-this-in-sfc */
import React, { useEffect } from 'react';
import Highcharts from 'highcharts/highstock';

// Import our demo components
import { Grid, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HighchartsReact from 'highcharts-react-official';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import moment from 'moment';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';
import { parseDateChart } from '../dashboard/utils';
import { FileType } from '../../utils/constants';
import useHandleChipClick from '../../utils/FilterChipHook';
import { useCommonContext } from '../header/context';

// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);

const stockOptions = {
  chart: {
    type: 'column',
    height: 360,
  },
  title: {
    text: '', // Explicitly set title to an empty string
  },
  subtitle: {
    text: '', // Explicitly set subtitle to an empty string
  },
  responsive: true,
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false, // Disable export/download options
  },
  yAxis: {
    title: {
      text: '',
    },
    showFirstLabel: false,
  },
};

function VeriticalBarChart(props) {
  const {
    dataDetails,
    listData,
    selectedItem,
    setSelectedItem,
    loading,
  } = props;
  const navigate = useNavigate();
  const handleChipClick = useHandleChipClick();
  const { reportType } = useCommonContext();
  const [snackbar, setSnackbar] = React.useState({
    state: false,
    navigateText: '',
    message: '',
    severity: 'success',
  });
  const [chartData, setChartData] = React.useState(null);

  useEffect(() => {
    if (dataDetails?.length) {
      const reportData = dataDetails?.map((report) => ({
        ...report,
        startedDate: (report?.startedDate || report?.start) && ((reportType === FileType.PLAYWRIGHT
          || reportType === FileType.HTML)
          ? parseDateChart(moment(Number(report?.startedDate)).format('YYYY-MM-DDTHH:mm:ss'))
          : parseDateChart(report?.startedDate || report?.start)),
      }));

      const isPlaywright = reportType === FileType.PLAYWRIGHT;
      const isCypress = reportType === FileType.CYPRESS;
      const isHtml = reportType === FileType.HTML;

      const series = (isPlaywright || isCypress) ? [
        {
          name: 'Passed',
          pointPlacement: -0.3,
          data: reportData?.map((report) => ({
            y: isPlaywright ? report?.expected : report?.passes,
            key: report?.reportId,
            type: report?.fileType,
          })),
        },
        {
          name: 'Failed',
          pointPlacement: -0.2,
          data: reportData?.map((report) => ({
            y: isPlaywright ? report?.unexpected : report?.failures,
            key: report?.reportId,
            type: report?.fileType,
          })),
        },
        {
          name: isPlaywright ? 'Flaky' : 'Pending',
          pointPlacement: -0.1,
          data: reportData?.map((report) => ({
            y: isPlaywright ? report?.flaky : report?.pending,
            key: report?.reportId,
            type: report?.fileType,
          })),
        },
        {
          name: 'Skipped',
          data: reportData?.map((report) => ({
            y: report?.skipped,
            key: report?.reportId,
            type: report?.fileType,
          })),
        },
      ] : [
        {
          name: 'Passed',
          pointPlacement: -0.3,
          data: reportData?.map((report) => ({
            y: report?.passed,
            key: report?.reportId,
            type: report?.fileType,
          })),
        },
        {
          name: 'Failed',
          pointPlacement: -0.2,
          data: reportData?.map((report) => ({
            y: report?.failed,
            key: report?.reportId,
            type: report?.fileType,
          })),
        },
        {
          name: isHtml ? 'Retried' : 'Ignored',
          pointPlacement: -0.1,
          data: reportData?.map((report) => ({
            y: report?.ignored,
            key: report?.reportId,
            type: report?.fileType,
          })),
        },
        {
          name: 'Skipped',
          data: reportData?.map((report) => ({
            y: report?.skipped,
            key: report?.reportId,
            type: report?.fileType,
          })),
        },
      ];
      setChartData({
        ...stockOptions,
        plotOptions: {
          series: {
            cursor: 'pointer',
            grouping: false,
            borderWidth: 0,
            maxPointWidth: 50,
            point: {
              events: {
                click() {
                  const { options } = this;
                  handleChipClick(this?.series?.name);
                  navigate(`/build/${options?.type}/${options?.key}`);
                },
              },
            },
          },
        },
        xAxis: {
          useHTML: true,
          animate: true,
          labels: {
            useHTML: true,
            style: {
              wordBreak: 'break-word',
              transformOrigin: '83% 32px',
              textAlign: 'left',
              width: '80px',
            },
          },
          categories: reportData?.map((report) => (`${report?.startedDate}`)),
        },
        series,
        colors: ['#5FD726', '#FF0000', '#000000', '#FFC72B'],
      });
    }
  }, [dataDetails]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedItem(value);
  };
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {!!listData?.length && <Grid display="flex" justifyContent="flex-end" mt={-4} mb={2}>
        <FormControl size="small" sx={{ m: 1, width: 200 }}>
          <InputLabel id="demo-multiple-name-label">Select Report Name</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={selectedItem}
            onChange={handleChange}
            input={<OutlinedInput label="Select Report Name" />}
          >
            {listData?.map((val) => (
              <MenuItem
                key={val}
                value={val}
              >
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>}
      {loading ? <Skeleton variant="rounded" animation="wave" />
        : <HighchartsReact
            highcharts={Highcharts}
            constructorType="chart"
            options={chartData}
        />}
      {snackbar.state && (
        <NavigateSnackbar snackbarObj={snackbar} setSnackbar={setSnackbar} />
      )}
    </div>
  );
}

VeriticalBarChart.propTypes = {
  dataDetails: PropTypes.arrayOf(),
  listData: PropTypes.PropTypes.arrayOf(),
  selectedItem: PropTypes.string,
  setSelectedItem: PropTypes.func,
  loading: PropTypes.bool,
};

VeriticalBarChart.defaultProps = {
  dataDetails: [],
  listData: [],
  selectedItem: '',
  setSelectedItem: () => '',
  loading: false,
};

export default VeriticalBarChart;
