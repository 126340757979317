import { styled } from '@mui/material/styles';
import {
  Button,
} from '@mui/material';

const BelowForgotText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  textAlign: 'center',
  fontSize: theme.fontSize.subHeaderFontSize,
  marginTop: theme.spacing(2),
  color: theme.palette.text.ternary,
}));
const LogoBox = styled('div')(({ theme }) => ({
  height: '100vh',
  background: theme.palette.primary.main,
}));
const ButtonForgotText = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(0.6),
  textTransform: 'none',
}));
const LoginFormBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0.6),
    display: 'grid',
    width: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  display: 'block',
  textAlign: '-webkit-center',
  justifyContent: 'center',
}));
const WelcomeTextStyling = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.primary.main,
  textAlign: 'center',
  display: 'block',
  fontSize: theme.fontSize.headerFontSize,
}));
const TextFieldBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
  margin: theme.spacing(5, 5, 0, 5),
}));
const CustomButton = styled(Button)(({ theme }) => ({
  background: '#1D6AE5',
  color: '#fff',
  fontSize: '20px',
  lineHeight: '120%',
  minWidth: '160px',
  padding: '12px 15px',
  fontWeight: 400,
  transition: 'all 0.4s',
  borderRadius: '10px',
  border: '1px solid #3F6398',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  margin: theme.spacing(1, 2),
  '&:hover': {
    background: '#fff',
    color: '#1D6AE5',
  },
}));

const BelowForgotBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    justifyContent: 'center',
  },
  display: 'flex',
  margin: theme.spacing(0, 5),
  justifyContent: 'center',
}));
export {
  BelowForgotText,
  BelowForgotBox,
  LogoBox,
  ButtonForgotText,
  LoginFormBox,
  WelcomeTextStyling,
  TextFieldBox,
  CustomButton,
};
