const route = {
  ROUTE_DASHBOARD: 'dashboard',
  ROUTE_PLAYWRIGHT_DASHBOARD: 'playwrightDashboard',
  ROUTE_CYPRESS_DASHBOARD: 'cypressDashboard',
  ROUTE_LOGIN: 'login',
  ROUTE_NEW_LOGIN: 'new-login',
  ROUTE_SIGNUP: 'signup',
  ROUTE_EMAIL_VERIFICATION: 'email-verification',
  ROUTE_ON_BOARDING_PAGE: 'on-boarding',
  ROUTE_HOME_PAGE: 'home',
  ROUTE_NEW_DASHBOARD_PAGE: 'new-dashboard',
  ROUTE_NEW_SIDENAV_PAGE: 'new-sidenav',
  ROUTE_BUILD_RUN_PAGE: 'build-run',
  ROUTE_REGISTER: 'register/:id',
  ROUTE_FORGOT: 'forgot',
  INTEGRATIONS: 'integrations',
  ROUTE_PERSONALINFO: 'personalinfo',
  ROUTE_REPORTID: 'reports/:id',
  ROUTE_COMPANIES: 'companylist',
  COMPANY_CREATE: 'createCompany',
  COMPANY_DETAIL: 'companyDetail/:id',
  COMPANY_EDIT: 'companyEdit/:id',
  CREATE_GROUP: 'createGroup/:id',
  GROUP_LIST: 'groupList',
  GROUP_ID: 'groupDetail/:id',
  GROUP_PAGE: 'grouppage',
  PROFILE_PAGE: 'getAllProfile',
  CREATE_PROFILE: 'createProflie/:cId/:groupsId',
  PROFILE_DETAIL: 'profileDetail/:id',
  // REPORT_LIST: 'reportlist',
  REPORT_LIST_BY_ID: 'view/report/XML',
  PROJECT_LIST: 'projectlist',
  INVITE_USER: 'inviteuser',
  HTML_REPORT: 'htmlreportlist',
  ROUTE_HTMLREPORTID: 'htmlreports/:id',
  ROUTE_BUILD: 'build/:reportType/:id',
  INVITE_EXPIRED: 'inviteExpired',
  USER_INVITE_PROJECT: 'userInviteProject',
  INVITED_PROJECT: 'invitedProject',
  VIEW_PROJECT: 'viewProject/:id',
  ACCEPT_PROJECT_INVITE: 'acceptProjectInvite/:id',
  ACCESS_TOKEN: 'accesstoken',
  VIEW_REPORT: 'viewTestReport/:id/:type',
  VIEW_REPORT_GUEST: 'shareTestReport/:id/XML',
  VIEW_NOTIFICATION: 'view/:id/:type',
  TEAM: 'team',
  // PLAYWRIGHT_LIST: 'reportlist/playwright',
  // CYPRESS_LIST: 'reportlist/cypress',
  REDIRECT: '/redirect',
};
export default route;
