/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Skeleton } from '@mui/material';

const chartOptions = {
  chart: {
    type: 'spline',
    height: 375,
    width: 350,
    marginLeft: 10, // Adjust the margin to give more space
    marginRight: 10, // Adjust the margin to give more space
  },
  title: {
    text: '',
  },
  xAxis: {
    visible: true,
    gridLineWidth: 1,
    gridLineColor: '#e0e0e0',
    labels: {
      enabled: true, // Enable labels to ensure data visibility
    },
  },
  yAxis: {
    visible: false,
  },
  plotOptions: {
    spline: {
      marker: {
        enabled: true,
        radius: 4,
        lineWidth: 1,
        lineColor: '#1D6AE5',
        fillColor: '#1D6AE5',
      },
    },
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    symbolRadius: 50,
    itemMarginBottom: 10,
    enabled: true,
    itemStyle: {
      fontWeight: 'normal',
      fontSize: '12px',
    },
  },
  tooltip: {
    pointFormat: '<b>Build<b> <br>Performance:<b>{point.y}%</b>',
  },
  credits: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 330, // Set a minimum width to avoid hiding the chart
        },
        chartOptions: {
          chart: {
            width: '100%',
          },
        },
      },
    ],
  },
};

const LineChartWithStats = ({ data, loading }) => {
  const [options, setOptions] = useState({});

  useEffect(() => {
    const dataList = {
      ...chartOptions,
      series: [
        {
          name: 'Build Performance',
          data: data && data?.stabilityPercents,
          color: '#1D6AE5',
        },
      ],
    };

    setOptions(dataList);
  }, [data]);

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {!!data?.reportStability && <div style={{ textAlign: 'left', marginBottom: '5px' }}>
        <div style={{ fontSize: '18x', fontWeight: 'bold', marginBottom: '2px' }}>{`${data?.reportStability}%`}</div>
        {!!data?.last30ReportStability && <div style={{ fontSize: '12px', color: '#6c757d' }}>
          {`Lower than ${data?.last30ReportStability} of the builds based on
          last ${data?.stabilityPercents?.length} runs`}
        </div>}
      </div>}
      {loading ? (
        <Skeleton variant="rounded" />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default LineChartWithStats;
