import axios from 'axios';
// import { reNewPluginAccessKeyToken } from './integrations';

const socialLogin = async (requestDto) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/social/token`, requestDto);
    if (response.data.accessToken) {
      localStorage.setItem('token', response.data.accessToken);
      // try {
      //   await reNewPluginAccessKeyToken();
      // } catch (err) {
      //   console.error('Failed to renew plugin access token:', err);
      // }
    }
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export default socialLogin;
