/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import framework1 from '../../assets/images/framework1.svg';
import framework2 from '../../assets/images/framewor2.svg';
import framework3 from '../../assets/images/framewor3.svg';
import whiteIcon from '../../assets/images/white-icon.svg';
import dashboardImg from '../../assets/images/Dashboard.png';
import analyticsDashboardImg from '../../assets/images/Web-Analytics-Dashboard.png';
import { DashboardEnum } from '../../utils/constants';
import userService from '../../services/user.service';
import route from '../../utils/route';
import NavigateSnackbar from '../navigateSnackbar/NavigateSnackbar';

const OnBoardingPage = () => {
  const [loading] = useState(false);
  const navigate = useNavigate();
  const [selectedFramework, setSelectedFramework] = useState(DashboardEnum.SELENIUM);
  const [navigateSnackbarState, setNavigateSnackbarState] = useState({
    navigateText: '',
    severity: '',
    state: false,
    message: '',
  });
  // const [projectSetUp, setProjectSetUp] = useState(false);
  // const [companyDetails, setCompanyDetails] = useState({});
  const initialValues = {
    companyName: '',
    dashboardType: selectedFramework,
    project: '',
  };

  const onBoardingSchema = Yup.object({
    companyName: Yup.string()
      .trim()
      .required('Please enter your company name'),
    dashboardType: Yup.string()
      .trim()
      .required('Please select a dashboard type'),
    project: Yup.string().trim().required('Project name is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: onBoardingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const res = await userService.updateSocialUser({
          companyName: values.companyName,
          framework: values.dashboardType,
          project: values.project,
        });
        if (res) {
          navigate(`/${route.ROUTE_HOME_PAGE}`, { replace: true });
        }
      } catch (err) {
        setNavigateSnackbarState({
          navigateText: '',
          severity: 'error',
          state: true,
          message: `${err?.data?.message || 'An error occurred'}`,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleFrameworkSelect = (framework) => {
    setSelectedFramework(framework);
    formik.setFieldValue('dashboardType', framework);
  };

  document.body.classList.add('loginBody');

  return (
    <>
      <header className="header-sec">
        <div className="header-wrap">
          <div className="logo-wrap">
            <Link to="/"><img src={logo} alt="logo" width="206" height="38" /></Link>
          </div>
          <div className="header-right-info">
            <span className="nap">Need any help?</span>
          </div>
        </div>
      </header>
      <div className="multistep-main-wrap">
        <div className="multistep-block-details">
          <div className="multistep-detail-block">
            <h1 className="h2">Create a new company and project name</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="input-wrap">
                <label className="label" htmlFor="companyName">Company Name</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter your company name"
                  name="companyName"
                  id="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.companyName && formik.touched.companyName && (
                  <span className="error-msg">{formik.errors.companyName}</span>
                )}
              </div>
              <div className="input-wrap">
                <label className="label" htmlFor="project">Project Name</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter your project name"
                  name="project"
                  id="project"
                  value={formik.values.project}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.project && formik.touched.project && (
                  <span className="error-msg">{formik.errors.project}</span>
                )}
              </div>
              <div className="select-framework-wrap">
                <p>Select Framework</p>
                <div className="select-framework-row">
                  <div
                    className={`framework-box ${selectedFramework === `${DashboardEnum.SELENIUM}` ? 'active' : ''}`}
                    onClick={() => handleFrameworkSelect(DashboardEnum.SELENIUM)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleFrameworkSelect(DashboardEnum.SELENIUM);
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    onBlur={() => formik.setFieldTouched('dashboardType', true)}
                  >
                    <img src={framework1} alt="framework1" width="85" height="85" />
                    <span>Selenium</span>
                  </div>
                  <div
                    className={`framework-box ${selectedFramework === `${DashboardEnum.PLAYWRIGHT}` ? 'active' : ''}`}
                    onClick={() => handleFrameworkSelect(DashboardEnum.PLAYWRIGHT)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleFrameworkSelect(DashboardEnum.PLAYWRIGHT);
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    onBlur={() => formik.setFieldTouched('dashboardType', true)}
                  >
                    <img src={framework2} alt="framework2" width="85" height="85" />
                    <span>Playwright</span>
                  </div>
                  <div
                    className={`framework-box ${selectedFramework === `${DashboardEnum.CYPRESS}` ? 'active' : ''}`}
                    onClick={() => handleFrameworkSelect(DashboardEnum.CYPRESS)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleFrameworkSelect(DashboardEnum.CYPRESS);
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    onBlur={() => formik.setFieldTouched('dashboardType', true)}
                  >
                    <img src={framework3} alt="framework3" width="85" height="85" />
                    <span>Cypress</span>
                  </div>
                </div>
                {formik.errors.dashboardType && formik.touched.dashboardType && (
                  <span className="error-msg">{formik.errors.dashboardType}</span>
                )}
              </div>
              <div className="next-prev-btn-wrap">
                <button type="submit" className="next-prev-btn active" disabled={loading}>
                  <span>Next</span>
                  <img src={whiteIcon} alt="arrow" />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="multistep-block-right">
          <div className="multistep-img-right">
            <div className="staper-block">
              <div className="step step-active">
                <div>
                  <div className="circle">1</div>
                </div>
                <div>
                  <div className="title">User Registration</div>
                  <div className="caption">Enter your company details</div>
                </div>
              </div>
              <div className="step">
                <div>
                  <div className="circle">2</div>
                </div>
                <div>
                  <div className="title active-title">Project Setup</div>
                  <div className="caption">Select your project type</div>
                </div>
              </div>
              <div className="step circle-border">
                <div>
                  <div className="circle">3</div>
                </div>
                <div>
                  <div className="title">Data Integration</div>
                  <div className="caption">Upload your API, XML, and HTML</div>
                </div>
              </div>
            </div>
            <div className="right-block-multistep">
              <img className="img-ds" src={dashboardImg} alt="Dashboard" width="400" height="350" />
              <img className="sd-img" src={analyticsDashboardImg} alt="Web-Analytics-Dashboard" width="640" height="420" />
            </div>
          </div>
        </div>
      </div>
      {
        navigateSnackbarState.state
        && (
          <NavigateSnackbar
            snackbarObj={navigateSnackbarState}
            setSnackbar={setNavigateSnackbarState}
          />
        )
      }
    </>
  );
};

export default OnBoardingPage;
