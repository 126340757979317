/* eslint-disable import/no-extraneous-dependencies */
import html2canvas from 'html2canvas';

const captureScreenshot = async (targetRef) => {
  if (!targetRef?.current) {
    console.error('Ref is not valid');
    return null;
  }

  try {
    // Capture the screenshot of the element using the ref
    const canvas = await html2canvas(targetRef.current);

    // Convert canvas to Blob
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, 'image/jpeg'); // Convert canvas to Blob with specified format
    });

    return blob; // Return the Blob object
  } catch (error) {
    console.error('Error capturing screenshot:', error);
    return null;
  }
};

export default captureScreenshot;
