/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  Box, Typography, Button, Chip,
  Tooltip,
  ListItemText,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import stopwatchIcon from '../../../assets/stopwatch.png';
import DonutChart from '../../chart/DonutChart';
import { getDuration } from '../../Home/TestData';
import { PlatwrightTestStatus } from '../../../utils/constants';
import { useCommonContext } from '../../header/context';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F5F5F5',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  summaryHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: theme.spacing(2),
    border: '1px solid #000000',
    margin: theme.spacing(2, 0),
    boxShadow: '-5px 5px 0px 0px #FFC72B',
    borderRadius: theme.spacing(1),
  },
  summaryTitleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },
  summaryTitle: {
    fontWeight: 600,
    fontSize: '19px',
  },
  stepSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testSummmaryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
  },
  testHeaderMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  testWrapper: {
    width: '100%',
    padding: theme.spacing(1, 2),
    border: '1px solid #E1E1E1',
    margin: theme.spacing(2, 0, 0),
    boxShadow: 'none',
    borderRadius: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      background: '#EFEFEF',
    },
  },
  stepHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 2),
    // borderBottom: '1px solid #e0e0e0',
  },
  stepDetails: {
    padding: theme.spacing(2),
  },
  stepTitle: {
    display: 'flex',
    alignItems: 'baseline',
    maxWidth: '80%',
  },
  stepIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  TAG_BUTTON: {
    borderRadius: '24px',
    color: '#fff',
    fontWeight: '500',
    padding: theme.spacing(0.5, 2),
    whiteSpace: 'nowrap',
  },
  TO_INVESTIGATE: {
    backgroundColor: '#000000',
    color: '#fff',
    '&:hover': {
      background: '#A6A6A6',
    },
  },
  PRODUCT_BUG: {
    backgroundColor: '#FF0000',
    '&:hover': {
      background: '#BF0000',
    },
  },
  AUTOMATION_BUG: {
    backgroundColor: '#FFC72B',
    '&:hover': {
      background: '#CC9A22',
    },
  },
  NO_DEFECT: {
    backgroundColor: '#1D6AE5',
    '&:hover': {
      background: '#154EB0',
    },
  },
  ENVIRONMENT_ISSUE: {
    backgroundColor: '#5FD726',
    '&:hover': {
      background: '#4AA61E',
    },
  },
  iconError: {
    fontSize: '12px',
    color: '#FF0000',
    marginRight: theme.spacing(1),
  },
  iconSuccess: {
    fontSize: '12px',
    color: '#5FD726',
    marginRight: theme.spacing(1),
  },
  browserInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 0),
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #E7E7E7',
    '&:hover': {
      background: '#EFEFEF',
    },
  },
  chipSuccess: {
    color: '#fff',
    backgroundColor: '#5FD726',
    borderColor: '#5FD726',
    height: '25px',
  },
  chipFailure: {
    color: '#fff',
    backgroundColor: '#FF0000',
    borderColor: '#FF0000',
    height: '25px',
  },
  chipSkipped: {
    color: '#fff',
    backgroundColor: '#FFC72B',
    borderColor: '#FFC72B',
    height: '25px',
  },
  chipIgnored: {
    color: '#fff',
    backgroundColor: '#000000',
    borderColor: '#000000',
    height: '25px',
  },
}));

const PlaywrightTestSteps = ({
  tags, testData, changeTest, openTest, project, id,
}) => {
  const classes = useStyles();
  const { selectedfilter } = useCommonContext();
  const { stats } = testData?.reportResult;

  let listData = testData?.staticsResult;
  if (listData?.tests) {
    if (!Array.isArray(listData?.tests)) {
      listData = [listData?.tests];
    } else {
      listData = listData?.tests;
    }
  }

  return (
    <>
      <Box className={classes.testHeaderMain}>
        <Box>
          <Typography className={classes.summaryTitle} variant="h6">{testData?.reportResult?.projectNames[0]}</Typography>
        </Box>
        <Box className={classes.stepSummary}>
          <Box className={classes.stepIcons}>
            <Box mx={2} display="flex" alignItems="center">
              <img src={stopwatchIcon} width={20} alt="Stopwatch" />
              <Typography variant="body2" color="textSecondary" ml={0.5}>{getDuration(testData?.reportResult?.duration || 0)}</Typography>
            </Box>
          </Box>
          <Box display="flex" gap={1}>
            <Tooltip title="Expected">
              <Chip
                variant="outlined"
                label={stats?.expected}
                className={classes.chipSuccess}
              />
            </Tooltip>
            <Tooltip title="Failed">
              <Chip
                variant="Unexpected"
                label={stats?.unexpected}
                className={classes.chipFailure}
              />
            </Tooltip>
            <Tooltip title="Flaky">
              <Chip
                variant="outlined"
                label={stats?.flaky}
                className={classes.chipIgnored}
              />
            </Tooltip>
            <Tooltip title="Skipped">
              <Chip
                variant="outlined"
                label={stats?.skipped}
                className={classes.chipSkipped}
              />
            </Tooltip>
          </Box>
          <Box>
            <DonutChart
              donutChartPlayWrightData={{
                passed: stats?.expected,
                failed: stats?.unexpected,
                flaky: stats?.flaky,
                skipped: stats?.skipped,
              }}
            />
          </Box>
        </Box>
      </Box>

      {listData && listData?.map((item) => {
        const methods = item?.results;
        const itemArray = Array.isArray(methods) ? methods : [methods];
        const filteredMethods = itemArray?.filter((method) => {
          if (selectedfilter === PlatwrightTestStatus.PASSED) {
            return method.status === PlatwrightTestStatus.PASSED;
          }
          if (selectedfilter === PlatwrightTestStatus.FAILED) {
            return method.status === PlatwrightTestStatus.FAILED;
          }
          if (selectedfilter === PlatwrightTestStatus.SKIPPED) {
            return method.status === PlatwrightTestStatus.SKIPPED;
          }
          if (selectedfilter === PlatwrightTestStatus.FLAKY) {
            return method.status === PlatwrightTestStatus.FLAKY;
          }
          return true;
        });

        const passedTest = filteredMethods?.filter((a) => a.status === PlatwrightTestStatus.PASSED);
        const failedTest = filteredMethods?.filter((a) => a.status === PlatwrightTestStatus.FAILED);
        const skippedTest = filteredMethods
          ?.filter((a) => a.status === PlatwrightTestStatus.SKIPPED);
        const flakyTest = filteredMethods?.filter((a) => a.status === PlatwrightTestStatus.FLAKY);

        if (selectedfilter === PlatwrightTestStatus.PASSED && passedTest.length === 0) {
          return null;
        }
        if (selectedfilter === PlatwrightTestStatus.FAILED && failedTest.length === 0) {
          return null;
        }
        if (selectedfilter === PlatwrightTestStatus.SKIPPED && skippedTest.length === 0) {
          return null;
        }
        if (selectedfilter === PlatwrightTestStatus.FLAKY && flakyTest.length === 0) {
          return null;
        }

        const isFailed = failedTest.length > 0;
        const tagClass = tags[item?.title]?.tagName;

        return <>
          <Box
            onClick={() => {
              changeTest({
                id, test: item?.results[0], testName: item.title, project,
              }); openTest(true);
            }}
            className={classes.testWrapper}
          >
            <Box className={classes.stepHeader}>
              <Box className={classes.stepTitle}>
                <CircleIcon className={isFailed ? classes.iconError : classes.iconSuccess} />
                <Box>
                  <ListItemText
                    sx={{ flex: 1 }}
                    primary={item?.title}
                    secondary={`${item?.location?.file}:${item?.location?.line}`}
                  />
                </Box>
              </Box>
              <Box className={classes.stepSummary}>
                {isFailed
                  && <Button
                    className={`${classes.TAG_BUTTON} ${tagClass !== undefined
                      ? classes[tagClass]
                      : classes.TO_INVESTIGATE
                    }`}
                  >
                    {tagClass || 'TO INVESTIGATE'}
                  </Button>}
                <Box className={classes.stepIcons}>
                  <Box mx={2} display="flex" alignItems="center">
                    <img width={20} src={stopwatchIcon} alt="stopwatch" />
                    <Typography variant="body2" color="textSecondary" ml={0.5}>{getDuration(item?.duration) || 0}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>;
      })}
    </>
  );
};

export default PlaywrightTestSteps;

PlaywrightTestSteps.propTypes = {
  tags: {},
  testData: [],
  changeTest: PropTypes.func,
  openTest: PropTypes.func,
  project: PropTypes.string,
  id: PropTypes.string,
};

PlaywrightTestSteps.defaultProps = {
  tags: {},
  testData: [],
  changeTest: () => { },
  openTest: () => { },
  project: '',
  id: '',
};
