import React from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import CommonContext from './components/header/context';
import theme from './theme';
import routes from './routes';
import LayoutOutlet from './components/Layouts';
import NotFound from './components/NotFound';

function App() {
  if (process.env.NODE_ENV === 'production') {
    // Disable all console logs
    console.log = () => {};
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CommonContext>
          <BrowserRouter basename={process.env.REACT_APP_BASE_PATH || ''}>
            <Routes>
              {
                routes.map(
                  ({
                    path, component: Component, exact, isAuth,
                  }) => (
                    <Route
                      key={path}
                      exact={exact}
                      path={path}
                      element={<LayoutOutlet isAuth={isAuth}>{Component}</LayoutOutlet>}
                    />
                  ),
                )
              }
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </CommonContext>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
export default App;
