/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { Skeleton } from '@mui/material';

const MyHighChart = ({ chartData, loading }) => {
  const formatDuration = (ms) => {
    const duration = moment.duration(ms);
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m ` : ''}${seconds > 0 ? `${seconds}s` : ''}`;
  };

  // Map the data for xAxis (dates) and series (testExecutions and averageDuration)
  const categories = chartData && chartData?.map((item) => moment(item.date).format('D MMM'));
  const testExecutionsData = chartData && chartData?.map((item) => item.testExecutions);
  const averageDurationData = chartData && chartData?.map((item) => item.averageDuration);

  const options = {
    chart: {
      type: 'column',
      marginTop: 70,
    },
    title: {
      text: '',
    },
    responsive: true,
    credits: false,
    xAxis: {
      categories, // Use mapped categories for xAxis
    },
    yAxis: [
      {
        title: {
          text: 'Test Executions',
          style: {
            color: '#1D6AE5', // Apply blue color to the y-axis title
          },
        },
        min: 0,
        opposite: false,
        labels: {
          format: '{value}',
        },
      },
      {
        title: {
          text: '', // No title for the duration axis
        },
        min: 0,
        opposite: true,
        labels: {
          formatter() {
            return formatDuration(this.value);
          },
          style: {
            color: '#00ff00', // Green color for the y-axis labels
          },
        },
      },
    ],
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      symbolRadius: 8,
      itemStyle: {
        fontWeight: 'normal',
        fontSize: 15,
      },
    },
    tooltip: {
      formatter() {
        if (this.series.name === 'All Builds: Average Duration') {
          return `${this.series.name}: ${formatDuration(this.y)}`;
        }
        return `${this.series.name}: ${this.y}`;
      },
    },
    series: [
      {
        name: 'All Builds: Test Executions',
        data: testExecutionsData, // Mapped test executions data
        color: '#ffc107', // Yellow color for test executions
      },
      {
        name: 'All Builds: Average Duration',
        data: averageDurationData, // Mapped average duration data in ms
        yAxis: 1,
        color: '#00ff00', // Green color for average duration
      },
    ],
  };

  return (
    <>
      <div style={{ width: '100%', textAlign: 'center' }}>
        {loading ? (
          <Skeleton variant="rounded" animation="wave" />
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </div>
    </>
  );
};

export default MyHighChart;
