/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  CssBaseline,
  Container,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Breadcrumbs,
  Skeleton,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Tooltip,
  Avatar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DownloadIcon from '@mui/icons-material/Download';
import { usePDF } from 'react-to-pdf';
import HorizontalStackedBarChart from '../chart/BuildHistoryChart';
import DonutChart from '../chart/DonutChart';
import ColunmChart from '../chart/ColumnChart';
import VeriticalBarChart from '../chart/VeriticalBarChart';
import {
  cypressListByCurrentUser, getListByHtmlUserId, getListByUserId, playwrightListByCurrentUser,
} from '../../services/uploadFile.service';
import reportService from '../../services/report.service';
import dashboardService from '../../services/dashboard.service';
import { useCommonContext } from '../header/context';
import { calculateDateRange } from '../dashboard/utils';
import {
  FileType, plateformIcons, platformEnum, UserRole,
} from '../../utils/constants';
import MyHighChart from '../chart/BuildPerformanceGraph';
import LineChart from '../chart/LineStabilityChart';
import StackedAreaChart from '../chart/StackedAreaChart';
import { CustomPagination, dashboardStyles, StyledTablePagination } from '../header/style';
import Logo from '../../assets/test-report-logo-dark.png';
import Loader from '../../utils/loader/Loader';
import { toTitleCase } from '../../utils';

const labels = ['1D', '7D', '30D'];

const Dashboard = () => {
  const navigate = useNavigate();
  const classes = dashboardStyles();
  const {
    user, reportType, setActiveItem, setBuildRunTab, setReportType,
  } = useCommonContext();
  const headerRef = useRef();
  const downloadRef = useRef();
  const [listData, setListData] = useState(null);
  const [dataDetails, setDataDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState(2);
  const [selectedItem, setSelectedItem] = useState('');
  const [dateRange, setDateRange] = useState(calculateDateRange('30D'));
  const [summaryData, setSummaryData] = useState(null);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [buildSummary, setBuildSummary] = useState();
  const [buildLoader, setBuildLoader] = useState(false);
  const [unstableTests, setUnstableTest] = useState();
  const [uniqueLoader, setUniqueLoader] = useState(false);
  const [buildPerformanceLoader, setBuildPerformanceLoader] = useState(false);
  const [buildPerformance, setBuildPerformance] = useState();
  const [stabilityLoader, setStabilityLoader] = useState(false);
  const [buildStability, setBuildStability] = useState();
  const [flakinessLoader, setFlakinessLoader] = useState(false);
  const [flakiness, setFlakiness] = useState();
  const [testStatusLoader, setTestStatusLoader] = useState(false);
  const [testStatus, setTestStatus] = useState();
  const [failureCategories, setFailureCategories] = useState();
  const [failureCategoriesLoader, setFailureCategoriesLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [downloading, setDownloading] = useState(false);

  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: `Dashboard-${Date.now()}.pdf`,
    page: { margin: 2 },
  });

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const generatePDF = async () => {
    try {
      setDownloading(true);
      if (headerRef.current) {
        headerRef.current.style.display = 'block';
      }

      if (downloadRef.current) {
        downloadRef.current.style.display = 'none';
      }

      await toPDF();

      if (headerRef.current) {
        headerRef.current.style.display = 'none';
      }
      if (downloadRef.current) {
        downloadRef.current.style.display = 'block';
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDownloading(false);
    }
  };

  const generateQuery = () => {
    // const timestring = ![FileType.SELENIUM, FileType.XML, FileType.HTML
    // ].includes(reportType) ? 'startTime' : 'startedAt';
    const timestring = 'createAt';
    const fileType = reportType === FileType.SELENIUM ? FileType.XML : reportType;
    const filterAnd = `${timestring}|gte|${dateRange.startDate}&${timestring}|lte|${dateRange.endDate}`;
    const params = {
      fileType,
      filterAnd,
      pageNumber: `${page - 1}`,
      pageSize: `${rowsPerPage}`,
    };
    return new URLSearchParams(params).toString();
  };

  const getReportList = async () => {
    try {
      setLoader(true);
      let res;
      switch (reportType) {
        case FileType.SELENIUM:
        case FileType.XML:
          res = await getListByUserId();
          break;
        case FileType.HTML:
          res = await getListByHtmlUserId();
          break;
        case FileType.PLAYWRIGHT:
          res = await playwrightListByCurrentUser();
          break;
        case FileType.CYPRESS:
          res = await cypressListByCurrentUser();
          break;
        default:
          throw new Error('Unsupported report type');
      }
      if (res) {
        setListData(res);
        setSelectedItem(res[0]);
      }
    } catch (error) {
      console.log(error?.data?.message);
    } finally {
      setLoader(false);
    }
  };
  const getTop5XMLReport = async (reportName) => {
    try {
      setLoader(true);
      let res;
      switch (reportType) {
        case FileType.SELENIUM:
        case FileType.XML:
          res = await reportService.getTop5XMLReportByCurrentReport(reportName);
          break;
        case FileType.HTML:
          res = await reportService.top10SeleniumHtmlComparisonGraphReportByReportName(reportName);
          break;
        case FileType.PLAYWRIGHT:
          res = await reportService.getTop5PlayWrightReportByCurrentReport(reportName);
          break;
        case FileType.CYPRESS:
          res = await reportService.getTop5CypressReportByCurrentReport(reportName);
          break;
        default:
          throw new Error('Unsupported report type');
      }
      setDataDetails(res?.length ? res?.slice(0, 5) : null);
    } catch (error) {
      console.log(error?.data?.message);
    } finally {
      setLoader(false);
    }
  };
  const fetchSummaryData = async () => {
    try {
      setSummaryLoading(true);
      const query = generateQuery();
      const res = await dashboardService.getSummaryData(query);
      setSummaryData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setSummaryLoading(false);
    }
  };

  const fetchUnstableTest = async () => {
    try {
      setUniqueLoader(true);
      const query = generateQuery();
      const res = await dashboardService.getUnstableTest(query);
      setUnstableTest(res.content);
      setTotalPages(res.totalPages);
    } catch (error) {
      console.log(error);
    } finally {
      setUniqueLoader(false);
    }
  };

  const fetchBuildSummary = async () => {
    try {
      setBuildLoader(true);
      const query = generateQuery();
      const res = await dashboardService.getBuildSummary(query);
      setBuildSummary(res);
    } catch (error) {
      console.log(error);
    } finally {
      setBuildLoader(false);
    }
  };

  const fetchBuildPerformance = async () => {
    try {
      setBuildPerformanceLoader(true);
      const query = generateQuery();
      const res = await dashboardService.getPerformanceData(query);
      setBuildPerformance(res);
    } catch (error) {
      console.log(error);
    } finally {
      setBuildPerformanceLoader(false);
    }
  };

  const fetchStabilityData = async () => {
    try {
      setStabilityLoader(true);
      const query = generateQuery();
      const res = await dashboardService.getStabilityData(query);
      setBuildStability(res);
    } catch (error) {
      console.log(error);
    } finally {
      setStabilityLoader(false);
    }
  };

  const fetchFlakinessData = async () => {
    try {
      setFlakinessLoader(true);
      const query = generateQuery();
      const res = await dashboardService.getFlakinessData(query);

      setFlakiness(res);
    } catch (error) {
      console.log(error);
    } finally {
      setFlakinessLoader(false);
    }
  };

  const fetchTestStatusData = async () => {
    try {
      setTestStatusLoader(true);
      const query = generateQuery();
      const res = await dashboardService.getTestStatusData(query);

      setTestStatus(res);
    } catch (error) {
      console.log(error);
    } finally {
      setTestStatusLoader(false);
    }
  };

  const fetchFailureCategoriesData = async () => {
    try {
      setFailureCategoriesLoader(true);
      const query = generateQuery();
      const res = await dashboardService.getFailureCategoriesData(query);

      setFailureCategories(res);
    } catch (error) {
      console.log(error);
    } finally {
      setFailureCategoriesLoader(false);
    }
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    const selectedLabel = labels[newValue];
    const newDateRange = calculateDateRange(selectedLabel);
    setDateRange(newDateRange);
  };

  useEffect(() => {
    if (reportType) {
      fetchSummaryData(reportType);
      fetchBuildSummary(reportType);
      fetchBuildPerformance(reportType);
      fetchStabilityData(reportType);
      fetchFlakinessData(reportType);
      fetchTestStatusData(reportType);
      fetchFailureCategoriesData(reportType);
    }
  }, [reportType, dateRange]);

  useEffect(() => {
    if (reportType) fetchUnstableTest(reportType);
  }, [reportType, dateRange, page, rowsPerPage]);

  useEffect(() => {
    if (reportType) getReportList();
  }, [reportType]);

  useEffect(() => {
    if (selectedItem) getTop5XMLReport(selectedItem);
  }, [selectedItem, dateRange, reportType]);

  useEffect(() => {
    if (user?.role?.roleName !== UserRole.SUPER_ADMIN) {
      setActiveItem('Dashboard');
    } else {
      setActiveItem([FileType.SELENIUM, FileType.XML, FileType.HTML].includes(reportType)
        ? FileType.SELENIUM
        : reportType);
    }
  }, [user, reportType]);

  if (downloading) return <Loader />;
  return (
    <div ref={targetRef}>
      <Box ref={headerRef} className={classes.appBar} sx={{ display: 'none' }}>
        <div className={classes.logo}>
          <img src={Logo} alt="Logo" className={classes.logoImage} />
        </div>
      </Box>
      <Container maxWidth={false} className={classes.root}>
        <CssBaseline />
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" marginBottom={2}>
          <Typography color="textPrimary">Dashboard</Typography>
        </Breadcrumbs>
        <Box className={classes.heading}>
          <Typography fontWeight="600" variant="h4" gutterBottom>
            Dashboard
          </Typography>
          <Tooltip title="Dashboard Download">
            <Button onClick={generatePDF} ref={downloadRef} disabled={!summaryData?.testsSummary || loader || summaryLoading || buildLoader || uniqueLoader || buildPerformanceLoader || stabilityLoader || flakinessLoader || testStatusLoader || failureCategoriesLoader || downloading} className={classes.downloadIcon} variant="text" size="small">
              <DownloadIcon />
            </Button>
          </Tooltip>
        </Box>
        <Box sx={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center',
        }}
        >
          <Tabs
            value={selectedTab}
            indicatorColor="none"
            textColor="primary"
            alignItems="center"
            sx={{
              mx: 2,
              fontSize: 2,
              '& .MuiTabs-flexContainer': {
                alignItems: 'center',
              },
            }}
          >
            {labels && labels?.map((item) => <Tab
              key={item}
              className={`${classes.tab} ${labels[selectedTab] === item ? classes.balloon : ''}`}
              label={item}
              onClick={(event) => {
                if (item === 'Custom') {
                  handleTabChange(event, labels.indexOf(item));
                } else {
                  handleTabChange(event, labels.indexOf(item));
                }
              }}
            />)}
          </Tabs>
          {[FileType.XML, FileType.HTML, FileType.SELENIUM].includes(reportType) && (
            <ToggleButtonGroup
              sx={{ my: 2 }}
              className={classes.ToggleButtonGroup}
              value={(reportType === FileType.SELENIUM || reportType === FileType.XML)
                ? FileType.XML : FileType.HTML}
              exclusive
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  setReportType(newValue);
                }
              }}
              aria-label="Platform"
            >
              <ToggleButton
                value={FileType.XML}
                sx={{
                  backgroundColor: (reportType === FileType.XML || reportType === FileType.SELENIUM) ? '#1D6AE5 !important' : '#fff',
                  color: (reportType === FileType.XML || reportType === FileType.SELENIUM) ? '#FFF !important' : '#666',
                  textTransform: 'capitalize',
                  fontSize: '12px',
                }}
              >
                XML
              </ToggleButton>
              <ToggleButton
                value={FileType.HTML}
                sx={{
                  backgroundColor: reportType === FileType.HTML ? '#1D6AE5 !important' : '#fff',
                  color: reportType === FileType.HTML ? '#FFF !important' : '#666',
                  textTransform: 'capitalize',
                  fontSize: '12px',
                }}
              >
                HTML
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
        <Paper className={classes.summaryBox}>
          <Grid className={classes.summaryHeading}>
            <Typography className={classes.summaryTitle} variant="h6">Summary</Typography>
          </Grid>
          <Box className={classes.summaryBoxIn}>
            <Box className={classes.metricIcon}>
              <Box>
                <Typography className={classes.subtitle} variant="subtitle1">Test Summary</Typography>
                {summaryLoading ? <>
                  <Skeleton variant="text" animation="wave" />
                </> : <Typography variant="h5" className={classes.metricValue}>{summaryData?.testsSummary}</Typography>}
              </Box>
              {(summaryLoading || !!summaryData?.passed
                || !!summaryData?.failed || !!summaryData?.skipped || !!summaryData?.ignore)
                && <Box>
                  <DonutChart
                    loading={summaryLoading}
                    dashbaordsuitesdata={{
                      passed: summaryData?.passed,
                      failed: summaryData?.failed,
                      skipped: summaryData?.skipped,
                      ignored: summaryData?.ignore,
                    }}
                  />
                </Box>}
            </Box>
            <Box className={classes.metric}>
              <Typography className={classes.subtitle} variant="subtitle1">Failure Rate</Typography>
              {summaryLoading ? <>
                <Skeleton variant="text" animation="wave" width={100} />
              </> : <Typography variant="h5" className={classes.metricValue}>
                {summaryData?.failureRate && `${summaryData?.failureRate}%`}
              </Typography>}
            </Box>
            <Box className={classes.metric}>
              <Typography className={classes.subtitle} variant="subtitle1">Failure Count</Typography>
              {summaryLoading ? <>
                <Skeleton variant="text" animation="wave" width={100} />
              </> : <Typography variant="h5" className={classes.metricValue}>{summaryData?.failed}</Typography>}
            </Box>
            <Box className={classes.metricIcon}>
              <Box>
                <Typography className={classes.subtitle} variant="subtitle1">Stability</Typography>
                {summaryLoading ? <>
                  <Skeleton variant="text" animation="wave" />
                </> : <Typography variant="h5" className={classes.metricValue}>
                  {summaryData?.stability && `${summaryData?.stability}%`}
                </Typography>}
              </Box>
              {(summaryLoading || !!summaryData?.passed
                || !!summaryData?.failed || !!summaryData?.skipped || !!summaryData?.ignore)
                && <Box>
                  <ColunmChart
                    loading={summaryLoading}
                    labels={['Passed', 'Failed', 'Skipped', 'Ignored']}
                    datasets={[
                      {
                        label: 'Data',
                        data: [summaryData?.passed || 0, summaryData?.failed || 0,
                          summaryData?.skipped || 0, summaryData?.ignore || 0],
                      },
                    ]}
                  />
                </Box>}
            </Box>
            <Box className={classes.metric}>
              <Typography className={classes.subtitle} variant="subtitle1">Flakiness</Typography>
              {summaryLoading ? <>
                <Skeleton variant="text" animation="wave" width={100} />
              </> : <Typography variant="h5" className={classes.metricValue}>
                {summaryData?.flakiness && `${summaryData?.flakiness}%`}
              </Typography>}
            </Box>
            <Box className={classes.metric}>
              <Typography className={classes.subtitle} variant="subtitle1">Platforms Covered</Typography>
              {summaryLoading ? <>
                <Skeleton variant="text" animation="wave" width={100} />
              </> : <Box className={classes.metricValue} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {summaryData?.platformsName?.length
                  ? summaryData?.platformsName?.map((plate) => <Tooltip
                      title={toTitleCase(plate)}
                  >
                    <Avatar
                      src={plateformIcons[plate]}
                      sx={{ width: 24, height: 24, borderRadius: 0 }}
                    />
                  </Tooltip>) : <>
                    <Tooltip
                      title={toTitleCase(platformEnum.WINDOWS)}
                    >
                      <Avatar
                        src={plateformIcons[platformEnum.WINDOWS]}
                        sx={{ width: 24, height: 24, borderRadius: 0 }}
                      />
                    </Tooltip>
                  </>}
              </Box>}
            </Box>
          </Box>
        </Paper>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Paper className={classes.summaryBox}>
              <Grid className={classes.summaryHeading}>
                <Typography className={classes.summaryTitle} variant="h6">Comparison Graph</Typography>
              </Grid>
              <Box className={classes.summaryBoxIn}>
                {(loader || !!dataDetails)
                  ? <VeriticalBarChart
                      dataDetails={dataDetails}
                      listData={listData}
                      setSelectedItem={setSelectedItem}
                      selectedItem={selectedItem}
                      loading={loader}
                  // selectedReportName={selectedReportName}
                  />
                  : <Typography textAlign="center" variant="body2" color="textSecondary">No Data Available</Typography>}
              </Box>
            </Paper>
          </Grid>
          <Grid item sm={12} md={6}>
            <Paper height="450px" className={classes.summaryBox}>
              <Grid className={classes.summaryHeading}>
                <Typography className={classes.summaryTitle} variant="h6">Build Performance</Typography>
              </Grid>
              <Box className={classes.summaryBoxIn}>
                {(buildPerformanceLoader || !!buildPerformance?.length)
                  ? <MyHighChart chartData={buildPerformance} loading={buildPerformanceLoader} />
                  : <Typography textAlign="center" variant="body2" color="textSecondary">No Data Available</Typography>}
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
            <Paper className={classes.summaryBox}>
              <Grid className={classes.summaryHeading}>
                <Typography className={classes.summaryTitle} variant="h6">Build Summary</Typography>
              </Grid>
              <Box className={classes.summaryBoxIn}>
                {(buildLoader || !!buildSummary?.length) ? <HorizontalStackedBarChart
                  data={buildSummary}
                  reportType={reportType}
                  loading={buildLoader}
                />
                  : <Typography textAlign="center" variant="body2" color="textSecondary">No Data Available</Typography>}
              </Box>
            </Paper>
          </Grid>
          <Grid item sm={12} md={4}>
            <Paper className={classes.summaryBox}>
              <Grid className={classes.summaryHeading}>
                <Typography className={classes.summaryTitle} variant="h6">Stability</Typography>
              </Grid>
              <Box className={classes.summaryBoxIn}>
                {(stabilityLoader || !!buildStability?.length)
                  ? <LineChart chartType="buildStability" chartData={buildStability} loading={stabilityLoader} />
                  : <Typography textAlign="center" variant="body2" color="textSecondary">No Data Available</Typography>}
              </Box>
            </Paper>
          </Grid>
          <Grid item sm={12} md={4}>
            <Paper className={classes.summaryBox}>
              <Grid className={classes.summaryHeading}>
                <Typography className={classes.summaryTitle} variant="h6">Flakiness</Typography>
              </Grid>
              <Box className={classes.summaryBoxIn}>
                {(flakinessLoader || flakiness?.graphData?.length) ? <LineChart chartType="flakiness" chartData={flakiness} loading={flakinessLoader} />
                  : <Typography textAlign="center" variant="body2" color="textSecondary">No Data Available</Typography>}
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Paper className={classes.summaryBox}>
              <Grid className={classes.summaryHeading}>
                <Typography className={classes.summaryTitle} variant="h6">Tests By Status</Typography>
              </Grid>
              <Box className={classes.summaryBoxIn}>
                {(testStatusLoader || !!testStatus?.length) ? <StackedAreaChart chartType="TestsStatus" chartData={testStatus} loading={testStatusLoader} />
                  : <Typography textAlign="center" variant="body2" color="textSecondary">No Data Available</Typography>}
              </Box>
            </Paper>
          </Grid>
          <Grid item sm={12} md={6}>
            <Paper height="450px" className={classes.summaryBox}>
              <Grid className={classes.summaryHeading}>
                <Typography className={classes.summaryTitle} variant="h6">Failure Categories</Typography>
              </Grid>
              <Box className={classes.summaryBoxIn}>
                {(failureCategoriesLoader || !!failureCategories?.length) ? <StackedAreaChart chartType="FailureCategories" chartData={failureCategories} loading={failureCategoriesLoader} />
                  : <Typography textAlign="center" variant="body2" color="textSecondary">No Data Available</Typography>}
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.summaryBox}>
              <Grid className={classes.summaryHeading}>
                <Typography className={classes.summaryTitle} variant="h6">Top Unstable Tests</Typography>
              </Grid>
              <Box className={classes.summaryBoxIn}>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableHeader}>
                        <TableCell className={classes.headerCell}>Test</TableCell>
                        <TableCell className={classes.headerCell}>Project</TableCell>
                        <TableCell className={classes.headerCell}>Failure Count</TableCell>
                        <TableCell className={classes.headerCell}>Failure Rate</TableCell>
                      </TableRow>
                    </TableHead>
                    {uniqueLoader ? (
                      <TableRow>
                        <TableCell><Skeleton animation="wave" /></TableCell>
                        <TableCell><Skeleton animation="wave" /></TableCell>
                        <TableCell><Skeleton animation="wave" /></TableCell>
                        <TableCell><Skeleton animation="wave" /></TableCell>
                      </TableRow>
                    ) : (
                      <TableBody>
                        {unstableTests && unstableTests?.map((test, index) => (
                          <TableRow sx={{ cursor: 'pointer' }} onClick={() => { setBuildRunTab(1); navigate(`/build/${test.fileType}/${test.reportId}`); }} key={index}>
                            <TableCell width="60%">
                              <Typography fontSize={17} variant="body1">{test?.testName}</Typography>
                              <Typography fontSize={15} variant="body2" color="textSecondary">{test?.reportName}</Typography>
                            </TableCell>
                            <TableCell fontSize={15}>{test?.projectName}</TableCell>
                            <TableCell fontSize={15}>{test?.failureCount}</TableCell>
                            <TableCell fontSize={15}>
                              {test?.failureRate}
                              %
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
              {!!unstableTests?.length && totalPages && (
                <Box className={classes.paginationContainer}>
                  <StyledTablePagination
                    count={totalPages * rowsPerPage}
                    totalPages={totalPages}
                    page={page - 1}
                    onPageChange={handlePageChange}
                    ActionsComponent={() => <CustomPagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;
